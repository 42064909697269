<template>
    <div :class="[
        item.cannotEdit ? '' : 'preview',
        'overflow-container'
    ]">
        <div class="d-flex flex-column align-start">
            <div class="d-flex flex-grow-1 ">
                <h4 class="text-left" v-if="item.type === 'input_title'"> {{ item.title }} <span class="required-field"
                        v-if="item.required">*</span></h4>
                <h5 v-else class="text-left">{{ item.title }} <span class="required-field" v-if="item.required">*</span>
                </h5>
                <p class="ml-2" v-if="item.cannotEdit">(Cannot edit this field)</p>
            </div>
            <p class="text-left" v-if="item.description">{{ item.description }}</p>
            <v-radio-group v-if="item.type === 'input_single'">
                <span v-for="(option, i) in item.options" :key="i" class="d-flex align-center">
                    <v-radio disabled :label="option" :value="option" class="text-left"></v-radio>
                    <p class="ml-2 mb-0 text-caption" v-if="item.requireComment?.includes(option)">*(Requires comment)
                    </p>
                </span>
            </v-radio-group>
            <div v-if="item.type === 'input_multiple'">
                <span v-for="(option, i) in item.options" :key="i" class="d-flex align-center">
                    <v-checkbox density="comfortable" class="left-label " hide-details disabled :label="option"
                        :value="option"></v-checkbox>
                    <p class="ml-2 mb-0 text-caption" v-if="item.requireComment?.includes(option)">*(Requires comment)
                    </p>
                </span>

            </div>

            <v-textarea rows="2" disabled variant="outlined" class="flex-grow-1 list-width"
                v-if="item.type === 'input_free_text'"></v-textarea>
            <v-text-field v-if="item.type === 'input_number'" disabled variant="outlined" type="number"
                class="flex-grow-1 list-width" :prefix="item.prefix" :label="item.prefix"></v-text-field>
            <div v-if="item.type === 'input_list'" class="d-flex flex-column align-center list-width">
                <v-data-table disabled :headers=headers :items="['']" class="custom-header">
                    <template v-slot:item="{ item }">
                        <tr class="flex-nowrap">
                            <td v-for="header in headers" :key="header.value" style="min-width: 300px;">
                                <v-textarea disabled flat auto-grow placeholder="" rows="1" variant="outlined"
                                    class="text-area full-width margin-sides"></v-textarea>
                            </td>
                        </tr>
                    </template>

                    <template #bottom></template>

                </v-data-table>
                <v-btn class="mt-2 add-btn" :elevation="0">
                    Add
                </v-btn>

                <!-- show on overlay true - meaning only in the builder
                  cause otherwise in the preview drawer of the community,
                  there was some strange shadow in the whole card -->
                <div v-if="item.columns?.length > 2 && overlay" class="scroll-indicator right-indicator">
                </div>
            </div>


        </div>

        <div v-if="item.type === 'input_likert'" class="list-width">
            <v-table>
                <thead>
                    <tr>
                        <th></th>
                        <th v-for="(header, i) in item?.scale?.items" :key="i">
                            {{ header }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(question, rowIndex) in item?.questions" :key="rowIndex">
                        <td class="text-left">{{ question }}</td>
                        <td v-for="(item, colIndex) in item?.scale?.items" :key="colIndex">
                            <v-radio :disabled="true"></v-radio>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </div>

        <div v-if="item.type === 'dropdown_single'" class="list-width pa-2">
            <v-card elevation="2">
                <v-list class="text-left">
                    <v-list-item v-for="option in item?.options">
                        <div class="d-flex align-center"><span>{{ option }}</span>
                            <p class="ml-2 mb-0 text-caption" v-if="item.requireComment?.includes(option)">
                                *(Requires comment)</p>
                        </div>
                    </v-list-item>
                </v-list>
            </v-card>
        </div>

        <div v-if="item.type === 'dropdown_multiple'" class="list-width pa-2">
            <v-card elevation="2">
                <v-list class="text-left" multiple>
                    <v-list-item v-for="option in item?.options">
                        <div class="d-flex align-center">
                            <v-checkbox disabled density="comfortable" class="left-label flex-grow-0" hide-details 
                                ></v-checkbox>
                            <span>{{ option }}</span>
                            <p class="ml-2 mb-0 text-caption" v-if="item.requireComment?.includes(option)">
                                *(Requires comment)</p>
                        </div>
                    </v-list-item> </v-list>
            </v-card>
        </div>

        <div v-if="item.type === 'file'" class="list-width pa-2 text-left">
            <input disabled type="file" id="avatar" name="avatar" accept="image/png, image/jpeg" />
        </div>

        <div v-if="overlay" class="customOverlay" @click="select">
            <v-tooltip location="bottom" text="Click to edit">
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" class="ma-2" icon="mdi-pencil"></v-btn>

                </template>
            </v-tooltip>


        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, getCurrentInstance, onMounted } from 'vue'

const props = defineProps({
    item: Object,
    overlay: {
        type: Boolean,
        default: true
    }
})

const headers = ref([]);

onMounted(() => {
    if (props.item.type === 'input_list') {
        headers.value = props.item.columns.map(col => ({
            title: col, align: 'left', key: col, sortable: false
        }))
    }
})


const { emit } = getCurrentInstance()

const select = () => {
    if (props.item.cannotEdit) return
    emit('select')
}
</script>

<style scoped>
.overflow-container {
    overflow-x: auto;
    max-width: 100%;
}

.preview {
    display: flex;
    flex-direction: column;
}

.overflow-parent {
    overflow-x: auto;
}

.customOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.preview:hover .customOverlay {
    opacity: 1;
}

.customOverlay .v-btn {
    pointer-events: auto;
}

.add-btn {
    align-self: end;
}

.custom-header ::v-deep th {
    border-bottom: none !important;
}

.list-width {
    width: 100%;
}

.left-label ::v-deep .v-label {
    text-align: left;
}

.text-left {
    text-align: left;
}

.required-field {
    color: var(--bittersweet);
}

/* Indicate that more items are gonna be on the right */

.scroll-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    background: linear-gradient(90deg, transparent, rgba(196, 196, 196, 0.5));
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.scroll-indicator::after {
    content: '›';
    font-size: 24px;
    color: #6c6c6c;

}

.right-indicator {
    right: 0;

}
</style>
