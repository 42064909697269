<template>
    <v-sheet class="d-flex flex-column justify-center align-center mt-4" color="#f9fbfd">
        <v-card class="mdhub-card elevation-0" width="100%" height="100%">
            <v-card-title class="text-left mb-5 mt-7 ml-6">Clinic Data Deletion Settings</v-card-title>
            <v-list class="text-left ml-6">

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Data deletion</v-list-item-title>
                        <v-list-item-subtitle class="mt-2">Enable or disable periodical data deletion for your clinic</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="ml-3">
                        <v-switch v-model="dataDeletionEnabled" color="bittersweet"></v-switch>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item v-if="dataDeletionEnabled" class="mb-4">
                    <v-list-item-content>
                        <v-list-item-title>Deletion mode</v-list-item-title>
                        <v-list-item-subtitle class="mb-6 mt-2">Delete audios only or all sessions data</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn-toggle class="toggle-box d-flex justify-left mb-2 mb-0" v-model="dataDeletionSettingMode" rounded="l" color="grey-ligthen-5" group mandatory @update:model-value="saveSettings">
                            <v-btn :value="'audio_only'">Audios only</v-btn>
                            <v-btn :value="'sessions'">All Sessions</v-btn>
                        </v-btn-toggle>
                    </v-list-item-action>
                </v-list-item>

                <v-list-item v-if="dataDeletionEnabled">
                    <v-list-item-content>
                        <v-list-item-title>Period in days</v-list-item-title>
                        <v-list-item-subtitle class="mt-2">Period in days after which the data will be deleted (minimum 1)</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-text-field placeholder="Days (minimum 1)" v-model="dataDeletionSettingPeriod" color="accent" variant="underlined" type="number"></v-text-field>
                    </v-list-item-action>
                </v-list-item>
            
            </v-list>

            <div class="mb-6" v-if="dataDeletionEnabled">
                <v-btn class="mdhub-btn" :loading="isLoading" :disabled="saveButtonDisabled" @click="saveSettingsClicked()" elevation="0">Save</v-btn>
            </div>

            <v-snackbar v-model="isSnackbarShown" :timeout="3000" :color="snackbarColor">
                <span :style="{ color: snackbarTextColor }">{{ snackbarText }}</span>
            </v-snackbar>
        </v-card>
    </v-sheet>
</template>

<script setup>
import { defineProps, ref, watch, computed } from 'vue';
import getDocument from '@/composables/getDocument'
import useDocument from '@/composables/useDocument'

const props = defineProps({
    clinicId: String
})

const saveButtonDisabled = computed(() => {
    return dataDeletionSettingPeriod.value === null || dataDeletionSettingPeriod.value === '' || dataDeletionSettingPeriod.value < 1 || dataDeletionSettingPeriod.value > 365;
})

const dataDeletionEnabled = ref(false);
const dataDeletionSettingMode = ref('audio_only');
const dataDeletionSettingPeriod = ref(null);
const isLoading = ref(false);

const isSnackbarShown = ref(false);
const snackbarText = ref('');
const snackbarColor = ref('gray');
const snackbarTextColor =  ref('accent');

const { document: clinic } = getDocument('clinics', props.clinicId);
const { error, updateDocument } = useDocument('clinics', props.clinicId);

watch(clinic, (newVal) => {
    if (newVal) {
        dataDeletionEnabled.value = newVal.dataDeletionEnabled ?? false;
        if (newVal.dataDeletionSettings) {
            dataDeletionSettingMode.value = newVal.dataDeletionSettings.mode ?? 'audio_only';
            dataDeletionSettingPeriod.value = newVal.dataDeletionSettings.period ?? null;
        }
    }
})

watch(dataDeletionEnabled, async (newValue) => {
    if (clinic.value.dataDeletionEnabled !== newValue && newValue === false) {
        await updateDocument({ dataDeletionEnabled: false });
        showErrorIfNeeded();
    }  
})

const saveSettingsClicked = async () => {
    const dataDeletionSettings = {
        mode: dataDeletionSettingMode.value,
        period: dataDeletionSettingPeriod.value
    }

    const updateData = { dataDeletionEnabled: dataDeletionEnabled.value, dataDeletionSettings };
    isLoading.value = true;
    await updateDocument(updateData);
    isLoading.value = false;

    if (!showErrorIfNeeded()) {
        snackbarColor.value = 'gray';
        snackbarText.value = 'Data deletion settings saved successfully';
        snackbarTextColor.value = 'accent';
        isSnackbarShown.value = true;
    }  
}

function showErrorIfNeeded() {
    if (!error.value) {
        return false;
    }

    snackbarColor.value = 'bittersweet';
    snackbarText.value = 'Error while saving data deletion settings';
    snackbarTextColor.value = 'white';
    isSnackbarShown.value = true;
    return true;
}

</script>