import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getCombinedConversations = (myref, query, callback, order = 'desc', paginationOptions = { limit: 10, startAfter: null }, patientId = null) => {
    const conversations = ref([])
    const patientQuestionnaires = ref([])
    const error = ref(null)
    const lastVisible = ref({
        conversations: null,
        questionnaires: null
    })
    const hasMoreData = ref({
        conversations: true,
        questionnaires: true
    })
    const isLoading = ref(false)
    
    // Store the current pagination options
    const currentPaginationOptions = ref({
        limit: paginationOptions.limit || 10,
        startAfter: paginationOptions.startAfter || null
    })

    // Initial fetch
    fetchData()

    function fetchData() {
        isLoading.value = true

        const isLoadAll = currentPaginationOptions.value.limit > 100;

        let conversationsRef = projectFirestore
            .collection('conversations')
            .orderBy('startedAt', order)
    
        let questionnairesRef = projectFirestore
            .collection('patient-questionnaires')
            .where('type', '==', 'questionnaire_intake_agent')
            .orderBy('createdAt', order)

        if (!isLoadAll) {
            conversationsRef = conversationsRef.limit(currentPaginationOptions.value.limit)
            questionnairesRef = questionnairesRef.limit(currentPaginationOptions.value.limit)
        }
            
        // Apply filters if provided
        if (query) {
            conversationsRef = conversationsRef.where(...query)
            questionnairesRef = questionnairesRef.where(...query)
        }

        // Apply pagination cursor if available
        if (currentPaginationOptions.value.startAfter) {
            if (currentPaginationOptions.value.startAfter.conversations && hasMoreData.value.conversations) {
                conversationsRef = conversationsRef.startAfter(currentPaginationOptions.value.startAfter.conversations)
            }
            if (currentPaginationOptions.value.startAfter.questionnaires && hasMoreData.value.questionnaires) {
                questionnairesRef = questionnairesRef.startAfter(currentPaginationOptions.value.startAfter.questionnaires)
            }
        }

        // Fetch conversations
        const conversationsPromise = hasMoreData.value.conversations ? 
            conversationsRef.get() : 
            Promise.resolve({ docs: [] })

        // Fetch questionnaires
        const questionnairesPromise = hasMoreData.value.questionnaires ? 
            questionnairesRef.get() : 
            Promise.resolve({ docs: [] })

        // Process both queries
        Promise.all([conversationsPromise, questionnairesPromise])
            .then(([conversationsSnap, questionnairesSnap]) => {
                // Process conversations
                let conversationResults = []
                conversationsSnap.docs.forEach(doc => {
                    conversationResults.push({
                        ...doc.data(),
                        id: doc.id,
                        type: 'conversation',
                        requestType: doc.data().requestType ?? ['general_inquiry']
                    })
                })

                // Update last visible for conversations
                if (conversationsSnap.docs.length > 0) {
                    lastVisible.value.conversations = conversationsSnap.docs[conversationsSnap.docs.length - 1]
                }

                // Check if there are more conversations
                hasMoreData.value.conversations = conversationsSnap.docs.length >= currentPaginationOptions.value.limit

                // Process questionnaires
                let questionnaireResults = []
                questionnairesSnap.docs.forEach(doc => {
                    questionnaireResults.push({
                        ...doc.data(),
                        id: doc.id,
                        type: 'questionnaire',
                        requestType: ['schedule_appointment']
                    })
                })

                // Update last visible for questionnaires
                if (questionnairesSnap.docs.length > 0) {
                    lastVisible.value.questionnaires = questionnairesSnap.docs[questionnairesSnap.docs.length - 1]
                }

                // Check if there are more questionnaires
                hasMoreData.value.questionnaires = questionnairesSnap.docs.length >= currentPaginationOptions.value.limit

                // If this is the first fetch (no startAfter), set the value directly
                if (!currentPaginationOptions.value.startAfter) {
                    conversations.value = conversationResults
                    patientQuestionnaires.value = questionnaireResults
                } else {
                    // Otherwise append to existing data
                    conversations.value = [...conversations.value, ...conversationResults]
                    patientQuestionnaires.value = [...patientQuestionnaires.value, ...questionnaireResults]
                }

                // Combine and sort results
                combineResults()
                isLoading.value = false
            })
            .catch(err => {
                console.error("Error fetching data:", err)
                error.value = 'Could not fetch data'
                isLoading.value = false
            })
    }

    const combineResults = () => {
        if (conversations.value || patientQuestionnaires.value) {
            let combined = [
                ...conversations.value || [],
                ...patientQuestionnaires.value || []
            ]

            // Post-filter for patientId if provided
            // This handles the case where Firestore does not support multiple 'where' clauses with different fields
            if (patientId) {
                combined = combined.filter(item => {
                    // Look for patientId in common locations
                    return (
                        item.patientId === patientId || 
                        item.patient?.id === patientId || 
                        // Check in metadata of tasks for patientId
                        (item.tasks && item.tasks.some(task => {
                            if (task.metadata) {
                                // Check if metadata array has a string containing patientId
                                if (Array.isArray(task.metadata)) {
                                    return task.metadata.some(meta => 
                                        meta.includes(`Patient ID: ${patientId}`) || 
                                        meta.includes(`PatientId: ${patientId}`)
                                    );
                                }
                                // If metadata is an object with a patientId field
                                return task.metadata.patientId === patientId;
                            }
                            return false;
                        }))
                    );
                });
            }

            // Sort by timestamp (adjust sorting logic as needed)
            combined.sort((a, b) => {
                const aTime = a.startedAt?.toMillis() || a.createdAt?.toMillis() || 0
                const bTime = b.startedAt?.toMillis() || b.createdAt?.toMillis() || 0
                
                return order === 'desc' ? bTime - aTime : aTime - bTime
            })

            myref.value = combined
            
            if (typeof callback === 'function') {
                callback()
            }
        }
    }

    // Function to load the next page
    const loadMore = () => {
        if (isLoading.value) return
        
        // Only fetch more if we have more data available
        if (hasMoreData.value.conversations || hasMoreData.value.questionnaires) {
            // Update the pagination options for the next fetch
            currentPaginationOptions.value = {
                limit: currentPaginationOptions.value.limit,
                startAfter: lastVisible.value
            }
            
            // Fetch the next page
            fetchData()
        }
    }

    // Check if there's more data to load
    const hasMore = () => {
        return hasMoreData.value.conversations || hasMoreData.value.questionnaires
    }

    return {
        error,
        loadMore,
        hasMore: hasMore(),
        isLoading: isLoading
    }
}

export default getCombinedConversations