<template>
    <div class="container">
        <div class="d-flex">
            <h5 class="d-flex flex-row me-auto">Templates</h5>
        </div>
        <v-card elevation="0">
            <v-card-text class="d-flex justify-space-between action-bar-templates">
                <div class="d-flex align-center">
                    <v-text-field class="ml-6 custom-bg" style="width: 300px" v-model="search"
                        prepend-icon="mdi-magnify" label="Search Templates" base-color="#fff" single-line hide-details
                        variant="solo-filled" clearable flat></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>

                </div>
                <div class="d-flex align-center">
                    <v-btn class="white--text mb-2 text--lighten-1 mr-4" @click="importTemplate" variant="text">
                        <v-icon dense class="add-btn">mdi-plus</v-icon>
                        <span class="white--text add-btn"> Import Template </span>
                    </v-btn>
                    <v-btn @click="createTemplate" elevation="0" :loading="loading" color="#FF7260"
                        class="mdhub-btn mb-2">
                        Create Template
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>

        <div class="template-container" v-if="!loading">
            <v-card v-for="template in filteredTemplates" :key="template.id"
                class="text-left mt-4 rounded-border preview flex-item" elevation="0">
                <v-card-text class="card-content">
                    <div class="d-flex justify-space-between">
                        <div class="created-by">
                            {{ template.userName }}
                            <v-chip v-if="template.isMasterTemplate" size="x-small" color="primary" class="ml-2">
                                mdhub
                            </v-chip>
                        </div>
                        <div class="d-flex align-center">
                            <div class="mr-1">
                                <v-icon class="mr-2" size="x-small">mdi-share</v-icon>
                                <v-tooltip text="Times imported" location="bottom">
                                    <template v-slot:activator="{ props }">
                                        <span v-bind="props">{{ template.importsCount }}</span>
                                    </template>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>

                    <p class="text-h6 font-weight-bold template-title">{{ template.templateHeader }}</p>
                    <div class="d-flex justify-space-between align-start mt-1">
                        <div class="d-flex ml-1">
                            <v-chip class="white-icon">{{ template.selectedSpeciality }}</v-chip>
                        </div>
                        <div class="d-flex align-center">
                            <div class="section-count mr-2">
                                {{ template.sections.length }} sections
                            </div>

                            <v-btn variant="text" density="comfortable" color="primary"
                                @click="openSectionsDrawer(template)" class="sections-btn">
                                <v-icon start>mdi-menu</v-icon>
                                <v-tooltip activator="parent" location="top">View sections</v-tooltip>

                            </v-btn>
                        </div>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions v-if="!template.isMasterTemplate" class="d-flex align-center pa-4">
                    <v-spacer></v-spacer>
                    <v-btn variant="text" color="primary" class="action-btn mr-2" @click="clickRow(template)">
                        <v-icon start>mdi-pencil</v-icon>
                        Edit
                    </v-btn>
                    <v-btn variant="text" color="error" class="action-btn" @click="openDeleteDialog(template)">
                        <v-icon start>mdi-delete</v-icon>
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>

        <div v-else>
            <v-progress-circular class="mt-12" color="primary" indeterminate></v-progress-circular>
        </div>

        <div class="mt-12 d-flex flex-column align-center" v-if="!loading && templates.length === 0">
            <v-img height="200" width="200" class="image-onboarding" :src="require('@/assets/rocket.png')"
                alt="Banner Image" cover></v-img>
            <p>No templates yet? <span class="link" @click="createTemplate">Create your own</span> or <span
                    @click="importTemplate" class="link">import from the
                    community
                </span></p>
            <p class="mt-5">Want to know more? Check our <span class="text-decoration-underline"
                    style="cursor: pointer;" @click="openVideo">our video tutorial</span></p>
        </div>

        <v-navigation-drawer v-model="deleteDialog" temporary location="right" :width="600">
            <div class="d-flex flex-column align-start pa-4">
                <h5 class="text-left">Are you sure you want to delete the {{ selectedTemplate?.templateHeader }}?</h5>
                <p class="text-left">If you proceed you will no longer have acccess to it</p>
                <div class="mt-4">
                    <v-btn elevation="0" width="auto" class="mdhub-btn" @click="deleteDialog = false">
                        Cancel</v-btn>
                    <v-btn elevation="0" width="auto" :loading="deleting" @click="deleteTemplate">delete
                        template</v-btn>
                </div>
            </div>
        </v-navigation-drawer>

        <!-- New Sections Drawer -->
        <v-navigation-drawer v-model="sectionsDrawer" temporary location="right" :width="400">
            <div class="d-flex flex-column pa-4">
                <div class="d-flex justify-space-between align-center mb-4">
                    <div class="text-left">
                        <h5 class="text-h6">Template Sections</h5>
                    </div>
                    <v-btn icon="mdi-close" variant="text" @click="sectionsDrawer = false"></v-btn>
                </div>
                <div v-if="selectedTemplate" class="sections-list">
                    <div class="mb-4 text-left">
                        <h6 class="text-h6 font-weight-bold">{{ selectedTemplate.templateHeader }}</h6>
                        <v-chip class="mt-2">{{ selectedTemplate.selectedSpeciality }}</v-chip>
                    </div>
                    <v-list>
                        <v-list-item v-for="(section, index) in selectedTemplate.sections" :key="index"
                            class="text-left">
                            <template v-slot:prepend>
                                <v-icon size="small" class="mr-2">mdi-format-list-text</v-icon>
                            </template>
                            <v-list-item-title>{{ getSectionTitle(section) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>
            </div>
        </v-navigation-drawer>

        <Feedback />

    </div>
</template>

<script setup>
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import useDocument from '@/composables/useDocument'
import getCollection from '@/composables/getCollection'
import getMasterTemplates from '@/composables/getMasterTemplates'
import Feedback from '@/components/Feedback.vue';
import { trackEvent, TEMPLATE_VIDEO_CLICK } from '@/utilities/analyticsService';
import { transformTemplateItems } from '@/utilities/templateUtils';

const templates = ref([])
const router = useRouter()

const functions = getFunctions();

const search = ref('');
const filteredTemplates = computed(() => {
    if (search.value) {
        return templates.value.filter((template) => {
            const headerTrimmed = template.templateHeader.toLowerCase().replace(/ /g, '');
            const searchValueTrimmed = search.value.toLowerCase().replace(/ /g, '');
            return headerTrimmed.includes(searchValueTrimmed) || template.templateHeader.toLowerCase().includes(search.value.toLowerCase());
        });
    }
    return templates.value;
})

const loading = ref(false);

onMounted(async () => {
    await getAllTemplates();
})

const clinicTemplatesRef = ref([]);
const getAllTemplates = async () => {
    loading.value = true;

    const getSummaryTemplates = httpsCallable(functions, 'getSummaryTemplates');
    const getClinicTemplates = httpsCallable(functions, 'getClinicTemplates');
    const { documents: masterTemplates, load: loadMasterTemplates } = getMasterTemplates();
    await loadMasterTemplates();

    const [summaryTemplatesResult, clinicTemplatesResult] = await Promise.all([
        getSummaryTemplates(),
        getClinicTemplates({ clinicId: clinicId.value })
    ]);

    const processedSummaryTemplates = summaryTemplatesResult.data.templates.map(template => ({
        ...template,
        sections: transformTemplateItems(template.items)
    }));

    const processedClinicTemplates = clinicTemplatesResult.data.templates.map(template => ({
        ...template,
        sections: transformTemplateItems(template.items)
    }));
    console.log(masterTemplates.value)
    const processedMasterTemplates = masterTemplates.value.map(template => ({
        ...template,
        sections: transformTemplateItems(template.items),
        isMasterTemplate: true,
        userName: 'mdhub Team',
        templateHeader: template.title,
        selectedSpeciality: 'General',
        importsCount: 0
    }));

    clinicTemplatesRef.value = processedClinicTemplates;

    templates.value = [
        ...processedClinicTemplates,
        ...processedSummaryTemplates,
        ...processedMasterTemplates
    ];

    loading.value = false;
}

const clickRow = (template) => {
    router.push({ name: 'Template', params: { id: template.id } })
}

const createTemplate = () => {
    router.push({ name: 'TemplateCreate' })
}

const importTemplate = () => {
    router.push({ name: 'Community', query: { templatesSelected: true } })
}

const deleteDialog = ref(false);
const selectedTemplate = ref(null);

const openDeleteDialog = (item) => {
    selectedTemplate.value = item;
    deleteDialog.value = true;
}

const isUserAdmin = ref(false);
const clinicId = ref(null);
const deleting = ref(false);
const { documents: userObject } = getCollection('user');

watch(userObject, () => {
    clinicId.value = userObject.value[0]?.clinicId || null
    isUserAdmin.value = userObject.value[0]?.isAdmin || false;
})

const getSectionTitle = (section) => {
    return section.sectionTitle;
}

const deleteTemplate = async () => {
    deleting.value = true;
    const { deleteDocument } = useDocument('summary-templates', selectedTemplate.value.id)
    deleteDocument()
    if (isUserAdmin.value) {
        const restOfTemplates = clinicTemplatesRef.value.filter(t => t.id !== selectedTemplate.value.id)
        const { updateDocument: updateClinic } = useDocument('clinics', clinicId.value);
        const mappedTemplates = restOfTemplates.map(template => (
            {
                id: template.id,
                name: template.templateHeader
            }
        ));

        await updateClinic({
            templates: [...mappedTemplates]
        });

        console.log(`Template ${selectedTemplate.value.id}, deleted`)
    }

    getAllTemplates();
    deleting.value = false;
    deleteDialog.value = false;
}

const openVideo = async () => {
    trackEvent(TEMPLATE_VIDEO_CLICK, {
        userId: userObject.value[0]?.userId,
        userEmail: userObject.value.email
    })
    window.open('https://www.youtube.com/watch?v=aUeM-4Vxo_I', '_blank');
}

const sectionsDrawer = ref(false);

const openSectionsDrawer = (template) => {
    selectedTemplate.value = template;
    sectionsDrawer.value = true;
}
</script>

<style scoped>
.action-bar-templates {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

.rounded-table {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 0.5);
}

.add-btn {
    background-color: transparent !important;
    color: var(--bittersweet);
    text-transform: none !important;
    width: auto;
}

.btn-color {
    color: #FF7260 !important;
}

.customOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.preview:hover {
    background-color: #f5f5f5;
}

.template-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.flex-item {
    width: 100%;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

/* For mobile devices, switch to a single column */
@media (max-width: 768px) {
    .template-container {
        grid-template-columns: 1fr;
    }
}

.card-content {
    min-height: 160px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
}

.template-title {
    margin: 8px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.created-by {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
}

.section-count {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);
}

.expandable-content {
    background: #f5f5f5;
    padding-top: 16px;
    max-height: 0;
    transition: all 0.3s ease;
    opacity: 0;
    overflow: hidden;
}

.preview:hover .card-content {
    min-height: 160px;
}

.preview:hover .expandable-content {
    opacity: 1;
    max-height: 500px;
}

.white-icon :deep(.v-select__append-inner .v-icon) {
    color: white !important;
}

.container {
    padding: 24px;
}

.template-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    padding: 16px 0;
}

.preview {
    border: 1px solid #e5e7eb;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
}

.preview:hover {
    /* border-color: var(--bittersweet); */
}

.card-content {
    flex-grow: 1;
}

.created-by {
    color: #6b7280;
    font-size: 0.875rem;
}

.template-title {
    margin-top: 8px;
    margin-bottom: 16px;
}

.section-count {
    color: #6b7280;
    font-size: 0.875rem;
}

.sections-btn {
    color: #6b7280;
}

.sections-btn:hover {
    color: var(--bittersweet);
}

.white-icon {
    background-color: #f3f4f6;
    color: #374151;
}

.link {
    color: var(--bittersweet);
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.sections-list {
    height: calc(100vh - 100px);
    overflow-y: auto;
}

:deep(.v-list-item-title) {
    font-size: 0.875rem !important;
    color: #374151;
}

.action-btn {
    text-transform: none;
    font-weight: 500;
}
</style>