import { ref } from 'vue';
import { projectFirestore } from '../firebase/config';

const getLeads = async (clinicId) => {
  const leads = ref([]);
  const error = ref(null);
  const loading = ref(true);

  try {
    // Only fetch patients from the clinic collection since leads should be associated with a clinic
    const clinicCollectionRef = projectFirestore.collection('patients')
      .where('clinicId', '==', clinicId)
      .where('status', 'in', ['lead', 'patient'])
      .orderBy('createdAt', 'desc');

    const querySnapshot = await clinicCollectionRef.get();
    leads.value = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    error.value = null;
  } catch (err) {
    console.error("Firestore error while fetching leads:", err);
    leads.value = [];
    error.value = 'Could not fetch leads data';
  } finally {
    loading.value = false;
  }

  return { leads, error, loading };
};

export default getLeads; 