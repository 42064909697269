import { projectStorage } from "@/firebase/config";

async function insuranceImageUpload(file, questionnaire, metadata) {
    console.log(file)
    const path = `/insurance/images/${questionnaire.id}/${questionnaire.questionnaireId}/${questionnaire.patientId}/${questionnaire.userId}/${file.name}`;
    const fileStorageRef = projectStorage.ref(path);
    const result = await fileStorageRef.put(file, metadata);

    console.log('result', result);
    return path;
}

export default insuranceImageUpload;