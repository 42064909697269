import ForgotPassword from "@/components/ForgotPassword.vue";
import QuestionnaireClient from "@/components/questionnaire-client/QuestionnaireClient.vue";
import getNewVersion from '@/composables/getNewVersion';
import AllQuestionnaires from "@/views/AllQuestionnaires.vue";
import Calendar from "@/views/Calendar.vue";
import Questionnaire from "@/views/Questionnaire.vue";
import QuestionnaireSubmit from '@/views/QuestionnaireSubmit.vue';
import QuestionnaireSubmitted from '@/views/QuestionnaireSubmitted.vue';
import Questionnaires from "@/views/Questionnaires.vue";
import Referral from "@/views/Referral.vue";
import Template from "@/views/Template.vue";
import TemplateCreate from "@/views/TemplateCreate.vue";
import Templates from "@/views/Templates.vue";
import AICoordinator from "@/views/ai-coordinator/AICoordinator.vue";
import SmsChat from "@/views/ai-coordinator/SmsChat.vue";
import PatientChat from "@/views/patients/PatientChat.vue";
import PatientQuestionnaire from "@/views/patients/PatientQuestionnaire.vue";
import TreatmentPlan from "@/views/patients/TreatmentPlan.vue";
import Inbox from "@/views/sessions/Inbox.vue";
import { createRouter, createWebHistory } from "vue-router";
import { projectAuth } from "../firebase/config";
import BusinessAssociateAgreement from "../views/BusinessAssociateAgreement.vue";
import DeleteAccount from "../views/DeleteAccount.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsOfService from "../views/TermsOfService.vue";
import ThankU from "../views/ThankU.vue";
import ZoomDocumentation from "../views/ZoomDocumentation.vue";
import AdminPanel from "../views/admin/AdminPanel.vue";
import Login from "../views/auth/Login.vue";
import Signup from "../views/auth/Signup.vue";
import CreatePatient from "../views/patients/CreatePatient.vue";
import PatientDetails from "../views/patients/PatientDetails.vue";
import PatientOverview from "../views/patients/PatientOverview.vue";
import SessionDetails from "../views/patients/SessionDetails.vue";
import UserPatients from "../views/patients/UserPatients.vue";
import CreateSubscription from "../views/user/CreateSubscription.vue";
import UserDetails from "../views/user/UserDetails.vue";
import RedirectToQuestionnaire from "../views/RedirectToQuestionnaire.vue";

import Feedback from "@/components/Feedback.vue";
import Community from "@/views/Community.vue";
import ThankYou from "@/views/ThankYou.vue";
import {
  configureSdk,
  getIsZoomApp,
  getZoomConfigured,
  setIsZoomApp,
} from "../composables/configureZoom";


const setupZoom = async () => {

  if (window.navigator.userAgent.toLowerCase().includes("zoom")) {
    console.log('is Zoom app')
    const zoomConfigured = getZoomConfigured().zoomConfigured.value;
    setIsZoomApp(true);
    if (!zoomConfigured) {
      console.log('Zoom app is not configured')
      await configureSdk();
      console.log('Zoom app is configured')
    }
  }
};
const requireAuth = async (to, from, next) => {
  let user = projectAuth.currentUser;
  await setupZoom();
  if (!user) {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else {
    const { isZoomApp } = getIsZoomApp();
    if (isZoomApp.value) {
      await getNewVersion();
    }
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "patients",
    component: UserPatients,
    redirect: "/patients/user",
    beforeEnter: requireAuth,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: setupZoom,
  },
  {
    path: "/thanks",
    name: "ThankU",
    component: ThankU,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/zoom-documentation",
    name: "ZoomDocumentation",
    component: ZoomDocumentation,
  },
  {
    path: "/delete-account",
    name: "DeleteAccount",
    component: DeleteAccount,
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/business-associate-agreement",
    name: "BusinessAssociateAgreement",
    component: BusinessAssociateAgreement,
  },
  {
    path: "/patients/create",
    name: "CreatePatient",
    component: CreatePatient,
    beforeEnter: requireAuth,
  },
  {
    path: "/patients/:id",
    name: "PatientOverview",
    component: PatientOverview,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/patients/user",
    name: "UserPatients",
    component: UserPatients,
    beforeEnter: requireAuth,
  },
  {
    path: "/subscriptions",
    name: "CreateSubscription",
    component: CreateSubscription,
    beforeEnter: requireAuth,
    props: {
      details: true,
      margins: true,
    },
  },
  {
    path: "/details",
    name: "UserDetails",
    component: UserDetails,
    beforeEnter: requireAuth,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/community",
    name: "Community",
    component: Community,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/templates/new',
    name: 'TemplateCreate',
    component: TemplateCreate,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/templates/:id',
    name: 'Template',
    component: Template,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/patients/:patientId/session/:id",
    name: "SessionDetails",
    component: SessionDetails,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/patients/:patientId/treatment-plan",
    name: "TreatmentPlan",
    component: TreatmentPlan,
    props: true
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: Inbox,
    beforeEnter: requireAuth,
  },
  {
    path: "/patients/:patientId/details",
    name: "PatientDetails",
    component: PatientDetails,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/patients/:patientId/chat",
    name: "PatientChat",
    component: PatientChat,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/patients/:patientId/questionnaires/:patientQuestionnaireId",
    name: "PatientQuestionnaire",
    component: PatientQuestionnaire,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/admin",
    name: "AdminPanel",
    component: AdminPanel,
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: "/questionnaires",
    name: "Questionnaires",
    component: Questionnaires,
    beforeEnter: requireAuth,

  },
  {
    path: "/all-questionnaires",
    name: "AllQuestionnaires",
    component: AllQuestionnaires,
    beforeEnter: requireAuth,

  },
  {
    path: "/questionnaires/:id",
    name: "QuestionnaireBuilder",
    component: Questionnaire,
    beforeEnter: requireAuth,
    props: true,

  },
  {
    path: "/contact-us/fullPage",
    name: "ContactUs",
    component: Feedback,
    beforeEnter: requireAuth,
    props: true,

  },
  {
    path: "/questionnaire-patient",
    name: "QuestionnaireClient",
    component: QuestionnaireClient,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: "/questionnaire-patient/submitted",
    name: "QuestionnaireSubmitted",
    component: QuestionnaireSubmitted,
    meta: {
      hideNavbar: true
    },
  },
  {
    path: "/ai-coordinator",
    name: "AICoordinator",
    component: AICoordinator,
    beforeEnter: requireAuth,
  },
  {
    path: "/sms-chat",
    name: "SmsChat",
    component: SmsChat,
    beforeEnter: requireAuth,
  },

  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    beforeEnter: requireAuth,
  },
  {
    path: "/questionnaire-submit/",
    name: "QuestionnaireSubmit",
    component: QuestionnaireSubmit,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: ThankYou,
    beforeEnter: requireAuth,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/agent/:clinicName:/:short",
    name: 'RedirectToQuestionnaire',
    component: RedirectToQuestionnaire,
    meta: {
      hideNavbar: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(to => {
  if (to.name !== 'Login') {
    document.title = 'Powering healthcare with AI.'
  }
})

export default router;
