<template>
  <div v-if="isAdmin && clinicId" class="d-flex flex-column container" color="#f9fbfd">
    <v-tabs v-model="tab" slider-color="var(--bittersweet)">
      <v-tab value="details">Details</v-tab>
      <!-- <v-tab value="inbox">Inbox</v-tab> -->
    </v-tabs>
    <div>
      <v-window v-model="tab">
        <v-window-item value="details">
          <ClinicDetails :clinicId="clinicId" />
          <!-- <ImportPatients v-if="showImportPatients || canViewImport" :clinicId="clinicId"></ImportPatients> -->
          <ClinicUsers :clinicId="clinicId" />
          <ClinicTemplates :clinicId="clinicId" />
          <ClinicDataDeletionSettings v-if="isClinicDataDeletionEnabled" :clinicId="clinicId" />
        </v-window-item>
        <v-window-item value="inbox">
          <ClinicInbox />
        </v-window-item>
      </v-window>
    </div>


  </div>
</template>

<script setup>
import ClinicDetails from "@/components/ClinicDetails.vue";
import ClinicUsers from "@/components/ClinicUsers.vue";
import ClinicTemplates from "@/components/ClinicTemplates.vue";
import ClinicDataDeletionSettings from '@/components/ClinicDataDeletionSettings.vue'
import ImportPatients from "@/components/ImportPatients.vue";
import getUser from '@/composables/getUser'
import { ref, onMounted } from 'vue';
import { getUserCollection } from '@/composables/userService';
import useDocument from '@/composables/useDocument'
import { isWhiteListedAccount, clinicDataDeletionEnabled } from '@/composables/isWhitelistedAccount'
import ClinicInbox from './Inbox.vue'
import getDocumentOnce from "@/composables/getDocumentOnce";

const { user } = getUser()
const userObject = ref({});
const documentHelpers = ref(null);
const isAdmin = ref(false);
const clinicId = ref(null)
const showImportPatients = ref(false)
const canViewImport = ref(false);
const isClinicDataDeletionEnabled = ref(false);

canViewImport.value = isWhiteListedAccount();

const loadUserObject = async () => {
  if (user.value) {
    userObject.value = await getUserCollection(user.value.uid);
    if (userObject.value) {
      documentHelpers.value = useDocument('user', userObject.value.id)
      if (userObject.value.isAdmin) {
        isAdmin.value = true
        clinicId.value = userObject.value.clinicId;
        isClinicDataDeletionEnabled.value = clinicDataDeletionEnabled(userObject.value.clinicId);
      }
    }
    // if (user.value.uid == 'r6vBqrFiPPepmsPCOtLICMewArh1' || user.value.uid == 'kRtLPLzDeBc0rsRsyXeVlvqJ75I3' || user.value.uid == 'wm5C96bPP6Y2jgZr2cLHYshEyn02') {
    //   showImportPatients.value = true;
    // } else {
    //   showImportPatients.value = false;
    // }
    if (!!userObject.value.clinicId && isAdmin.value) {
      const { document: clinic } = await getDocumentOnce('clinics', userObject.value.clinicId)
      if (!!clinic.value?.sharePatients) {
        showImportPatients.value = true
      }
    }
  }
};

const tab = ref('details')

onMounted(async () => {
  await loadUserObject()
})

</script>

<style scoped></style>