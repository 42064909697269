export const getPatientFullName = (patient) => {
    if (!patient) return '';
    
    return patient.patientFirstName 
        ? `${patient.patientFirstName} ${patient.patientLastName || ''}`.trim()
        : patient.patientName || '';
}

export const SUPPORTED_LANGUAGES = [
	{ code: 'multi', display: 'Multilingual (EN & ES)' },
	{ code: 'en-US', display: 'English (US)' },
	{ code: 'en', display: 'English (Global)' },
	{ code: 'es', display: 'Spanish' },
	{ code: 'de', display: 'German' },
	{ code: 'fr', display: 'French' },
	{ code: 'pt', display: 'Portuguese' },
	{ code: 'it', display: 'Italian' },
	{ code: 'nl', display: 'Dutch' },
	{ code: 'ru', display: 'Russian' },
	{ code: 'hi', display: 'Hindi' },
	{ code: 'fa', display: 'Persian' },
	{ code: 'ro', display: 'Romanian' },
	{ code: 'ar', display: 'Arabic' },
	{ code: 'zh', display: 'Chinese' },
	{ code: 'pa', display: 'Punjabi' },
	{ code: 'tl', display: 'Tagalog' },
	{ code: 'ja', display: 'Japanese' },
	{ code: 'ko', display: 'Korean' },
	{ code: 'pl', display: 'Polish' },
	{ code: 'th', display: 'Thai' },
	{ code: 'vi', display: 'Vietnamese' },
	{ code: 'el', display: 'Greek' },
	{ code: 'id', display: 'Indonesian' },
	{ code: 'tr', display: 'Turkish' }
];

export const SUPPORTED_LANGUAGES_SORTED = SUPPORTED_LANGUAGES.sort((a, b) => a.display.localeCompare(b.display));

export const ALL_SUPPORTED_LANGUAGE_OPTIONS = () => {
    return [{ code: null, display: 'Detect language' }, ...SUPPORTED_LANGUAGES_SORTED];
}

export const TEMPLATE_LANGUAGES = () => {
	return SUPPORTED_LANGUAGES_SORTED
		.filter(lang => lang.code !== 'multi' && lang.code !== 'en-US')
		.map(lang => lang.display === 'English (Global)' ? 'English' : lang.display);
}