// For the outgoing events
import { ref } from 'vue'
const env = ref(process.env.VUE_APP_NODE_ENV || process.env.NODE_ENV)
const useEnv = () => {
    console.log('env', env.value, process.env.VUE_APP_NODE_ENV, process.env.NODE_ENV)
    // or any other port that the localhost runs - we need to fix that for production
    // return 'http://localhost:8080'
    return env.value === 'localhost' ? 'http://localhost:8080' : (env.value === 'production' ? 'https://mdhub-calendar-production.web.app' : 'https://mdhub-calendar.web.app')
}

export { useEnv }
