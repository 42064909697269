<template>
    <div v-if="clinicId" class="calendar-container">
        <v-overlay :model-value="isOpen" persistent class="align-center justify-center spinner-overlay" />
        <iframe ref="schedulerRef" :class="iframeClasses" :src="SCHEDULER_URL" @load="initializeScheduler" />
    </div>
</template>

<script setup>
import getUser from '@/composables/getUser';
import { getUserObject } from '@/composables/getUserObject';
import { useEnv } from '@/composables/useEnv';
import { getFunctions, httpsCallable } from "firebase/functions";
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

const permittedOrigins = ['http://localhost:8080', 'https://mdhub-calendar.web.app', 'https://mdhub-calendar-production.web.app'] // needs the production as well
const SCHEDULER_URL = useEnv();
const schedulerRef = ref(null);
const isOpen = ref(false);
const userObject = ref({});
const clinicId = ref('');
const token = ref('');

const iframeClasses = computed(() => ({
    'iframe-container': true,
    'static-iframe': isOpen.value
}));

const { user } = getUser();

const sendMessageToScheduler = (type, data) => {
    schedulerRef.value?.contentWindow.postMessage(
        { type, data },
        SCHEDULER_URL
    );
};

const handleSchedulerMessage = (event) => {
    if (!permittedOrigins.includes(event.origin)) return;

    const { type } = event.data;
    switch (type) {
        case 'DETAILS_OPENED':
            isOpen.value = true;
            break;
        case 'DETAILS_CLOSED':
            isOpen.value = false;
            break;
        default:
            console.warn('Unhandled scheduler message type:', type);
    }
};

const initializeScheduler = async () => {
    const functions = getFunctions();
    const encryptUserToken = httpsCallable(functions, 'encryptUserToken');
    const encryptedToken = await encryptUserToken({ authToken: token.value });
    sendMessageToScheduler('TOKEN', encryptedToken);
    sendMessageToScheduler('CLINIC_ID', clinicId.value);
};

const initializeUserData = async () => {
    try {
        token.value = await user.value.getIdToken();
        userObject.value = await getUserObject();
        clinicId.value = userObject.value.clinicId || '';
    } catch (error) {
        console.error('Failed to initialize user data:', error);
    }
};

watch(isOpen, (newValue) => {
    if (newValue) {
        document.querySelector('.v-main').scrollIntoView()
    }
});

onMounted(() => {
    window.addEventListener('message', handleSchedulerMessage);
    initializeUserData();
});

onUnmounted(() => {
    window.removeEventListener('message', handleSchedulerMessage);
});
</script>

<style scoped>
.iframe-container {
    width: 100%;
    height: 100vh;
    border: none;
    position: relative;
    top: -23px;
}

.static-iframe {
    z-index: 10000;
}
</style>