<template>
    <div class="yes">
        <div :class="['d-flex', 'flex-column', 'align-start']">
            <h6 class="text-left">{{ item.title }} <span class="required-field" v-if="item.required">*</span></h6>
            <p v-if="item.description" class="text-left">{{ item.description }}</p>
            <div v-if="item.type === 'input_single'" class="d-flex flex-column list-width">
                <v-radio-group v-model="item.response">
                    <span v-for="(option, i) in item.options" :key="option + i"
                        class="d-flex align-center justify-start">
                        <v-radio :label="option" :value="option" class="text-left"></v-radio>
                        <p class="ml-2 mb-0 text-caption" v-if="item.requireComment?.includes(option) && preview">
                            *(Requires comment)</p>
                    </span>

                </v-radio-group>
                <v-textarea variant="outlined" v-if="item.requireComment?.includes(item.response)"
                    :label="`If you selected ${item.response} please explain`" v-model="item.comment"></v-textarea>
            </div>
            <div v-if="item.type === 'input_multiple'" class="d-flex flex-column list-width">
                <v-checkbox hide-details v-for="(option, i) in item.options" :key="i" v-model="item.response"
                    :label="option" :value="option" class="left-label mb-1"></v-checkbox>
                <v-textarea variant="outlined" v-if="item.response?.some(c => item.requireComment?.includes(c))"
                    :label="`If you selected any of ${item.requireComment?.join(', ')}, please explain`"
                    v-model="item.comment"></v-textarea>
            </div>

            <v-textarea variant="outlined" class="flex-grow-1 list-width" v-model="item.response"
                v-if="item.type === 'input_free_text'" rows="2" auto-grow></v-textarea>
            <v-text-field v-if="item.type === 'input_number'" type="number" variant="outlined"
                class="flex-grow-1 list-width" v-model="item.response" :prefix="item.prefix" hint="Must be a number"
                persistent-hint></v-text-field>

            <TableScrollable :item="item" v-if="item.type === 'input_list'" :preview="preview" />

            <Likert v-if="item.type === 'input_likert'" :item="item" :preview="preview" />

            <div v-if="item.type === 'dropdown_single'" class="d-flex flex-column list-width">
                <v-select v-model="item.response" variant="outlined" class="list-width"
                    :items="item.options"></v-select>
                <v-textarea variant="outlined" v-if="item.requireComment?.includes(item.response)"
                    :label="`If you selected ${item.response} please explain`" v-model="item.comment"></v-textarea>
            </div>

            <div v-if="item.type === 'dropdown_multiple'" class="d-flex flex-column list-width">
                <v-select v-model="item.response" multiple variant="outlined" class="list-width"
                    :items="item.options"></v-select>
                <v-textarea variant="outlined" v-if="item.response?.some(c => item.requireComment?.includes(c))"
                    :label="`If you selected any of ${item.requireComment?.join(', ')}, please explain`"
                    v-model="item.comment"></v-textarea>
            </div>

            <div v-if="item.type === 'file'" class="d-flex flex-column list-width">
                <v-progress-circular v-if="uploadingImage" class="mx-auto my-2" color="primary" indeterminate></v-progress-circular>

                <v-file-input append-inner-icon="mdi-magnify" v-model="item.file" v-if="!preview"
                    :hint="item.description" accept="image/*"></v-file-input>
                <div v-else>
                    <v-img :src="imageUrl" width="300px" height="300px"></v-img>
                </div>
            </div>

            <p v-if="error" class="required-field">{{ error }}</p>

        </div>

    </div>
</template>

<script setup>
import { ref, defineProps, toRef, onMounted, watch } from 'vue';
import TableScrollable from './TableScrollable.vue';
import Likert from './Likert.vue';
import insuranceImageUpload from '@/composables/useInsuranceImageUpload';
import getInsuranceImageUrl from '@/composables/getInsuranceImage';
import {
    trackEvent,
    INSURANCE_IMAGE_UPLOAD,
    INSURANCE_IMAGE_UPLOAD_ERROR
} from '@/utilities/analyticsService';
const props = defineProps({
    item: Object,
    error: String,
    preview: {
        type: Boolean,
        default: false
    },
    questionnaire: Object
});

const imageUrl = ref('');

const loadImage = async (path) => {
    try {
        imageUrl.value = await getInsuranceImageUrl(path);
    } catch (error) {
        console.error('Error loading image:', error);
    }
};

const getImage = async (path) => {
    const src = await getInsuranceImageUrl(path);
    return src
}
onMounted(() => {

    // if(props.preview) {
    //     return
    // }

    if (props.item.type === 'input_list') {
        if (props.preview) {
            props.item.response = transformToInitialFormat(props.item.response);
        } else {
            props.item.response = [[]]
            props.item.response[0] = props.item.columns.map(() => '')
        }

    }

    if (props.item.type === 'input_multiple') {
        if (!props.preview) {
            props.item.response = []
        }
    }

    if (props.item.type === 'input_likert') {
        if (!props.preview) {
            props.item.response = props.item.questions?.map((question) => {
                return {
                    question,
                    response: ''
                }
            })
        }
    }

    if (props.item.type === 'dropdown_multiple') {
        if (!props.preview) {
            props.item.response = []
        }
    }

    if (props.item.type === 'file') {
        if (!props.preview) {
            props.item.file = null
        } else {
            loadImage(props.item.response);
        }
    }



})

const uploadingImage = ref(false);

watch(() => props.item.file, async (newFile) => {
    if (newFile) {

        try {
            uploadingImage.value = true;
            const metadata = { contentType: newFile[0].type };
            const path = await insuranceImageUpload(newFile[0], props.questionnaire, metadata);
            console.log('File uploaded successfully:', path);



            props.item.response = path;
            trackEvent(INSURANCE_IMAGE_UPLOAD, {
                questionaire: props.questionnaire,
                file: JSON.stringify(newFile[0])
            })
        } catch (error) {
            trackEvent(INSURANCE_IMAGE_UPLOAD_ERROR, {
                questionaire: props.questionnaire,
                file: JSON.stringify(newFile[0])

            })
            console.error('Error uploading file:', error);
        }finally{
            uploadingImage.value = false;
        }
    }
});

const transformToInitialFormat = (transformedData) => {
    const result = [];

    const numColumns = transformedData.length;
    const numRows = transformedData[0].responses.length;

    for (let i = 0; i < numRows; i++) {
        const row = [];
        for (let j = 0; j < numColumns; j++) {
            row.push(transformedData[j].responses[i]);
        }
        result.push(row);
    }

    return result;
}
</script>

<style scoped>
.customOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.yes:hover .customOverlay {
    opacity: 1;
}

.add-btn {
    align-self: end;
}

.custom-header ::v-deep th {
    border-bottom: none !important;
}

.list-width {
    width: 100%;
}

.required-field {
    color: var(--bittersweet);
}

.left-label ::v-deep .v-label {
    text-align: left;
}

.text-left {
    text-align: left;
}
</style>
