<template>
    <v-container>
        <div class="search-replace-wrapper">
            <v-card v-if="openSearch" class="search-replace-card" elevation="0">
                <v-text-field class=" ml-6" v-model="searchModel" label="Search" color="accent" variant="underlined"
                    density="compact" clearable flat></v-text-field>
                <v-text-field class="ml-6" v-model="replaceModel" label="Replace with" color="accent"
                    variant="underlined" density="compact" clearable flat></v-text-field>
                <template v-slot:actions>
                    <v-btn class="ml-2 mr-2" @click="searchAndReplace">Replace me</v-btn>
                    <v-btn icon @click="closeSearch">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-card>
        </div>
        <div v-if="!pendingSummary" v-for="(clinicaNoteSection, index) in clinicalNoteSections">
            <div style="position:relative;" class="pb-5 pa-2 editorContainer text-left" v-if="editing"
                :class="{ 'editorContainerSelected': index === currentIndex }" v-on:keydown="onKeyDown">
                <OnClickOutside @trigger="onFocusout(index)">
                    <div class="d-flex mb-2">
                        <SavingSpinner label="Saving" v-if="currentSavingIndex == index && isPendingSave" />
                        <SessionNoteBlockActions class="noteActions" :note="editedNote" :disabledCopy="disabledCopy"
                            @show-snackbar="updateSnackbarText" @delete-section="informSectionDeletion(index)"
                            @show-price-banner="showPriceBanner" @copy-note-section="copyNoteSection(index)"
                            @send-section-to-ehr="sendSectionToEhr(index)" />
                    </div>
                    <h3 :id="'section_' + index" v-html="clinicaNoteSection.section"
                        :class="disabledCopy ? 'non-selectable' : ''">
                    </h3>
                    <div class="editableContent" :id="'editor_' + index"
                        :contenteditable="disabledCopy ? 'false' : 'true'" :class="disabledCopy ? 'non-selectable' : ''"
                        @click="editNote(index)" v-html="parseContentForTimestamps(clinicaNoteSection.content)">
                    </div>
                    <NotePrompter class="mt-5" @generate="generate" @undo="undo" @redo="redo"
                        :summaries="generatedSummaries" :index="currentIndex" :loading="generating"
                        v-if="currentIndex == index" />
                </OnClickOutside>
            </div>
            <div v-else class="text-left pa-2 my-2 editorContainer">
                <h3 class="mb-0" v-html="clinicaNoteSection.section"></h3>
                <div v-html="parseContentForTimestamps(clinicaNoteSection.content)"></div><br>
            </div>
        </div>
        <div class="d-flex justify-center flex-column align-items-start" style="margin:auto"
            v-if="!summaryFailure && pendingSummary">
            <div v-for="item in prevSessionStates">
                <div style="text-align: left" v-if="item.title === currentSessionState">
                    <v-progress-circular class="mr-1" indeterminate :rotate="90" :size="20"
                        :width="2"></v-progress-circular>
                    {{ item.description }}
                </div>
                <div style="text-align: left" v-else>
                    <v-icon color="green">mdi-check</v-icon>
                    {{ item.description }}
                </div>
            </div>
        </div>
        <div v-if="summaryFailure && !pendingSummary"> {{ summaryFailureText }}</div>
        <v-snackbar v-model="snackbar" timeout="2000" color="gray">
            {{ snackbarText }}
        </v-snackbar>
        <v-navigation-drawer persistent temporary location="right" :width="800" v-model="deleteDialog" max-width="500">
            <v-card height="100%">
                <v-card-title class="text-h5 mt-6 d-flex justify-center title-wrap">
                    Are you sure you want to delete the section?
                </v-card-title>
                <v-card-item class="mb-6 text-center">
                    This action permanently deletes the section of this clinical note, making
                    it
                    non-retrievable.
                </v-card-item>
                <v-card-actions class="mb-2">
                    <v-spacer></v-spacer>
                    <v-btn class="mdhub-btn" variant="text" @click="deleteDialog = false">Cancel</v-btn>
                    <v-btn color="blue-darken-1" variant="text" @click="deleteSection"
                        :loading="deletingSection">Delete</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-navigation-drawer>
    </v-container>

</template>

<script setup>

import { ref, watch, nextTick, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';
import getDocument from '@/composables/getDocument';
import useDocument from '@/composables/useDocument';
import SessionNoteBlockActions from './SessionNoteBlockActions.vue';
import SavingSpinner from './SavingSpinner.vue';
import { OnClickOutside } from '@vueuse/components'
import NotePrompter from './NotePrompter.vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import { trackEvent, NOTE_SECTION_REGENERATE, NOTE_SECTION_COPY } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router';
import moment from 'moment'

const functions = getFunctions();


const { emit } = getCurrentInstance();
const props = defineProps({
    id: String,
    copyTrigger: String,
    sendEhr: String,
    searching: Boolean,
    userId: String,
    patientId: String,
    userEmail: String,
    sessionId: String,
    disabledCopy: Boolean,
    editing: Boolean,
    modality: String,
    sessionStart: String,
    sessionEnd: String,
    sessionDate: String,
    templateChanged: Boolean
});

const { user } = getUser();

const router = useRouter();

const SessionState = {
    Created: "created",
    InProgressTranscript: "in_progress_transcript",
    InProgressSummary: "in_progress_summary",
    Completed: "completed",
}

const SessionStateFailure = {
    Transcript: "failure_transcript",
    Summary: "failure_summary",
}

const SessionAbortState = {
    AudioTooShort: "audio_too_short",
    NonMedicalConversation: "non_medical_conversation"
}

const { error, document: session } = getDocument('sessions', props.id);

const { updateDocument } = useDocument('sessions', props.id);

const clinicalNoteSections = ref([]);
const editMode = ref(false);
const currentIndex = ref(null);
const snackbar = ref(false);
const snackbarText = ref('');
const isPendingSave = ref(false);
const pendingSummary = ref(false);
const summaryFailure = ref(false)
const summaryFailureText = ref('')
const currentSessionState = ref('');
const prevSessionStates = ref([]);

const currentSavingIndex = ref(null);
const editedNote = ref({
    section: '',
    content: ''
});
const editedSection = ref({
    section: '',
    content: ''
});

const tempNewSections = ref([]);

const deleteDialog = ref(false);
const deletingSection = ref(false);
const currentIndexToDelete = ref(null);

const searchModel = ref('');
const replaceModel = ref('');
const openSearch = ref(false);

const updateSnackbarText = (text) => {
    snackbar.value = true;
    snackbarText.value = text;
}

// Edit section

const editNote = (index) => {
    if (props.disabledCopy) {
        showPriceBanner()
        return
    }
    if (currentIndex.value === index) {
        return
    }
    currentIndex.value = index;
    editMode.value = true

    nextTick(() => {
        // document.getElementById('editor').focus();
        editedNote.value = {
            section: document.getElementById('section_' + currentIndex.value).innerHTML,
            content: document.getElementById('editor_' + currentIndex.value).innerHTML
        }
    })

}
const onFocusout = (index) => {
    if (currentIndex.value !== index) {
        return
    }
    tempNewSections.value = []
    updateSection();
}

const getTempEditedSections = () => {
    editedNote.value = {
        section: document.getElementById('section_' + currentIndex.value).innerHTML,
        content: document.getElementById('editor_' + currentIndex.value).innerHTML
    }

    const newSections = clinicalNoteSections.value.map((section, index) => {
        if (index === currentIndex.value) {
            return editedNote.value
        }
        return section;
    });

    tempNewSections.value = newSections;
}

const onKeyDown = ($event) => {
    getTempEditedSections();

}

const updateSection = async () => {
    editMode.value = false;
    isPendingSave.value = true;
    currentSavingIndex.value = currentIndex.value;
    const content = document.getElementById('editor_' + currentIndex.value);
    const section = document.getElementById('section_' + currentIndex.value);
    const innerHTMLContent = content.innerHTML;

    const innerHTMLSection = section.innerHTML;
    snackbar.value = true;
    editedSection.value = {
        section: innerHTMLSection,
        content: innerHTMLContent
    }

    const newSections = clinicalNoteSections.value.map((section, index) => {
        if (index === currentIndex.value) {
            return editedSection.value
        }
        return section;
    });



    tempNewSections.value = newSections;

    try {
        currentIndex.value = null;
        await updateDocument({ summaryJson: JSON.stringify(newSections) });
        // delete session in pinecone
        const functions = getFunctions();
        const onDeleteVectors = httpsCallable(functions, 'on_delete_namespace_vectors');
        var response = await onDeleteVectors({ patient_id: props.patientId, session_id: props.sessionId, user_id: props.userId });

        if (response.data === 'success') {
            console.log(`Session vectors deleted successfully`);
            await updateDocument({ vectorized: false });
            console.log(`Session marked as not vectorized`);
        } else {
            console.error(`Error deleting session vectors: ${response.data.error}`);
        }

        snackbarText.value = 'Note automatically saved';
        console.log('New sections saved');
    } catch (e) {
        // here we need an event in posthog
        snackbarText.value = 'Something went wrong. Note could not be saved';
        console.error('Error saving new sections:', e);
    } finally {
        // clinicalNoteSections.value = [];
        setTimeout(() => {
            isPendingSave.value = false;
            currentSavingIndex.value = null;
        }, 500);

    }
}

// Delete section

const informSectionDeletion = (index) => {
    deleteDialog.value = true;
    currentIndexToDelete.value = index;
    return;
}

const deleteSection = () => {
    deletingSection.value = true;
    console.log('DELETING')
    try {
        const newSections = clinicalNoteSections.value.filter((_section, index) => index !== currentIndexToDelete.value);
        clinicalNoteSections.value = newSections;
        tempNewSections.value = newSections
        updateDocument({ summaryJson: JSON.stringify(newSections) });
        editMode.value = false;
        snackbar.value = true;
        snackbarText.value = 'Note section deleted';
    } catch (e) {
        console.error('Error deleting section:', e);
    } finally {
        deleteDialog.value = false;
        deletingSection.value = false;
        currentIndexToDelete.value = null;
        generatedSummaries[currentIndexToDelete.value] = null
    }
}

//Search and replace

const searchAndReplace = () => {
    try {
        const regex = new RegExp('\\b' + searchModel.value + '\\b', 'g');

        const newSections = clinicalNoteSections.value.map((section) => {
            return {
                section: section.section.replace(regex, replaceModel.value),
                content: section.content.replace(regex, replaceModel.value)
            }
        });

        clinicalNoteSections.value = newSections;
        tempNewSections.value = newSections
        updateDocument({ summaryJson: JSON.stringify(newSections) });
        snackbarText.value = 'Note automatically saved';
        snackbar.value = true;

    } catch (e) {
        // here we need an event in posthog
        snackbarText.value = 'Something went wrong. Note could not be saved';
        console.error('Error saving new sections:', e);
    } finally {
        setTimeout(() => {
            isPendingSave.value = false;
        }, 2000);
        closeSearch();
    }

};

const closeSearch = () => {
    emit('close-search');
    openSearch.value = false;
}

const stripHtml = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;

    let sections = [];
    tmp.querySelectorAll('.copy-section').forEach(section => {
        let title = section.querySelector('h3').textContent.trim();
        let content = '';

        if (section.querySelector('div > li')) {
            content = Array.from(section.querySelectorAll('div > li'))
                .map(li => li.textContent.trim())
                .join('\n');
        } else if (section.querySelector('div > p')) {
            content = section.querySelector('div > p').textContent
                .split(/(?<=\.|\:)\s+/)
                .map(sentence => sentence.trim())
                .join('\n');
        }

        sections.push(title + '\n' + content);
    });

    return sections.join('\n\n');
};

const stripSectionHtml = (html) => {
    let tmpHtml = html
        .replace(/<h3[^>]*>(.*?)<\/h3>/g, '\n$1\n\n')
        .replaceAll(/<br>/g, '\n ')

    const tmp = document.createElement('div');
    tmp.innerHTML = tmpHtml

    let text = tmp.textContent || tmp.innerText || '';
    text = text
        .replace(/^\s+/, '')
        .replace(/\s+$/, '')
        .split('\n')
        .map(line => line.trim())
        .join('\n')
        .replace(/\n{3,}/g, '\n\n');

    return text;
};


function sendMessageToExtension(clinicalNote) {
    try {

        let modality = '';
        if (props.modality == 'telehealth') {
            modality = 'Telehealth';
        } else {
            modality = 'Office (in-person)';
        }

        const clinicalNoteCopy = JSON.parse(JSON.stringify(clinicalNote));

        clinicalNoteCopy.push({
            section: 'Modality',
            content: modality
        });

        console.log('Session start', props.sessionStart, 'Session end', props.sessionEnd, 'Session date', props.sessionDate);

        let sessionStart = '';
        if (props.sessionStart) {
            sessionStart = props.sessionStart;
            console.log("Pushing session start", sessionStart);
            clinicalNoteCopy.push({
                section: 'SessionStart',
                content: sessionStart
            });
        }

        let sessionEnd = '';
        if (props.sessionEnd) {
            sessionEnd = props.sessionEnd;
            console.log("Pushing session end", sessionEnd);
            clinicalNoteCopy.push({
                section: 'SessionEnd',
                content: sessionEnd
            });
        }
        let sessionDate = '';
        if (props.sessionDate) {
            sessionDate = props.sessionDate;
            console.log("Pushing session date", sessionDate);
            clinicalNoteCopy.push({
                section: 'SessionDate',
                content: sessionDate
            });
        }

        // First, send the field mappings // 
        let chromeExtensionId = 'pajfhbfaefgojpclodacoipdfemepbck';
        //let chromeExtensionId = 'gmhehffhdcalddmbaihmdojbiehicfjj'; 
        chrome.runtime.sendMessage(chromeExtensionId, {
            type: 'UPDATE_FIELD_MAPPINGS',
            platform: 'advancemd',
            mappings: {
                "Chief Complaint": ["Ctrl8433589", "Ctrl10505624", "Ctrl10523420", "Ctrl10526878", "Ctrl10528539", "Ctrl7079859_txtSelect", "Ctrl7081867_txtSelect"],
                "History of Present Illness - detailed": ["Ctrl8433591", "Ctrl10505626", "Ctrl10523422", "Ctrl10526880", "Ctrl10528541", "Ctrl7079861_txtSelect", "Ctrl7081869_txtSelect"],
                "History of Present Illness": ["Ctrl7081869_txtSelect"],
                "Measurement-Informed Care (MIC)": ["Ctrl8433593", "Ctrl10505628", "Ctrl10523428", "Ctrl10526887", "Ctrl10528547"],
                "Suicide Risk Assessment": ["Ctrl10528550"],
                "Risk Assessment": ["Ctrl8433596", "Ctrl10505631", 'Ctrl10523431', "Ctrl10526944"],
                "Substance Use": ["Ctrl8433602", "Ctrl10505637", 'Ctrl10523437', "Ctrl10526947", "Ctrl10528556"],
                "Past Psychiatric History": ["Ctrl8433604", "Ctrl10505639", 'Ctrl10523439', "Ctrl10526950", "Ctrl10528558", "Ctrl7079929_txtSelect"],
                "Current Psychiatric Medication": ["Ctrl8433606", "Ctrl10505641", 'Ctrl10523441', "Ctrl10526954", "Ctrl10528560"],
                "Past Psychiatric Medication": ["Ctrl8433608", "Ctrl10505643", "Ctrl10523443", "Ctrl10526956", "Ctrl10528562"],
                "Current Non-Psychiatric Medication": ["Ctrl8433610", "Ctrl10505645", "Ctrl10523445", "Ctrl10526958", "Ctrl10528564"],
                "Past Medical and Surgical History": ["Ctrl8433612", "Ctrl10505647", "Ctrl10523447", "Ctrl10526970", "Ctrl10528566", "Ctrl7079950"],
                "Allergies": ["Ctrl10527002"],
                "Psychiatric Family History": ["Ctrl8433614", "Ctrl10505649", "Ctrl10523449", "Ctrl10527005"],
                "Family Medical History": ["Ctrl8433614", "Ctrl10505649",],
                "Social History ": ["Ctrl8433616", "Ctrl10505651", "Ctrl10523451", "Ctrl10527008", "Ctrl10528741"],
                "Allergies, Vital Signs, PCP Information": [],
                "Vital Signs": ["Ctrl8433618", "Ctrl10505813", "Ctrl10523453", "Ctrl10528743"],
                "Review of Systems": ["Ctrl10528745"],
                "MSE": ["Ctrl8433620", "Ctrl10505815", "Ctrl10523455", "Ctrl10527012", "Ctrl10528747"],
                "Controlled Medication Prescription Checklist": ["Ctrl8433622", "Ctrl10505817", "Ctrl10523457", "Ctrl10528755"],
                "Assessment": ["Ctrl8433624", "Ctrl10505819", "Ctrl10523461", "Ctrl10527071", "Ctrl10528759", "Ctrl7080113_txtSelect", "Ctrl7082072_txtSelect"],
                "Plan": ["Ctrl8433627", "Ctrl10505822", "Ctrl10523464", "Ctrl10527073", "Ctrl10528761"],
                "Therapy Note": ["Ctrl10528763"],
                "Psychotherapeutic Interventions": ["Ctrl8433629", "Ctrl10505824", "Ctrl10523466", "Ctrl7080209_txtSelect", "Ctrl7082083_txtSelect"],
                "Modality": ["Ctrl8433576", "Ctrl10523407", "Ctrl10526861"],
                "Patient Summary": ["Ctrl10523468", "Ctrl10527087"],
                "ROS": ['Ctrl10527010'],
                "After Visit Summary": ['Ctrl10528765'],
                "Current Medication": ['Ctrl10528767'],
                "Family History": ['Ctrl7079952_txtSelect'],
                "Mental Status Exam": ['Ctrl7080094_txtSelect', "Ctrl7081970_txtSelect"],
                "Plan": ['Ctrl7080196_txtSelect', 'Ctrl7082072_txtSelect'],
                "Treatment Progress": ['Ctrl7082072_txtSelect'],
                "Treatment Plan": ['Ctrl7082072_txtSelect'],
                "Social History": ['Ctrl7080021_txtSelect'],
                "Current Medication": ['Ctrl7079901_txtSelect', "Ctrl7081890_txtSelect"],
                "Response": ['Ctrl7082083_txtSelect'],
            }

        }, response => {
            if (chrome.runtime.lastError) {
                console.error('Error sending mappings:', chrome.runtime.lastError);
            } else {
                console.log('Mappings sent successfully:', response);

                // Then send the clinical note data
                chrome.runtime.sendMessage(chromeExtensionId, {
                    type: 'FROM_PAGE',
                    action: 'RECEIVE_SUMMARY',
                    data: clinicalNoteCopy
                }, response => {
                    if (chrome.runtime.lastError) {
                        console.error('Error sending message:', chrome.runtime.lastError);
                    } else {
                        console.log('Message sent successfully:', response);
                    }
                });
            }
        });

    } catch (error) {
        console.error('Failed to send message:', error);
    }
}

const sendSectionToEhr = (index) => {
    sendMessageToExtension(clinicalNoteSections.value.slice(index, index + 1));
}

const defineSummaryState = (newSession) => {
    summaryFailure.value = false;
    switch (newSession.state) {
        case SessionState.Completed:
            clinicalNoteSections.value = JSON.parse(newSession.summaryJson);
            pendingSummary.value = false;
            prevSessionStates.value = [];
            return;
        case SessionState.InProgressSummary:
        case SessionState.InProgressTranscript:
        case SessionState.Created:
            pendingSummary.value = true;
            break;
        // Failed states handling    
        default:
            defineFailureState(newSession.state);
            break;
    }
    currentSessionState.value = newSession.state;
    console.log('Session completed', prevSessionStates.value, newSession.state);
    // we don't want to push the same state twice    

    if (prevSessionStates.value.find(state => state.title === currentSessionState.value)) return;

    prevSessionStates.value.push({
        title: currentSessionState.value,
        description: defineSuccessStateDescription()
    });
}

const defineSuccessStateDescription = () => {
    switch (currentSessionState.value) {
        case SessionState.Completed:
            return 'Clinical note has been generated';
        case SessionState.InProgressTranscript:
            return 'Session transcript is being generated';
        case SessionState.InProgressSummary:
            return 'Generating clinical note';
        case SessionState.Created:
            return 'Session is being created';
    }
}

const defineFailureState = (state) => {
    switch (state) {
        case SessionStateFailure.Summary:
            summaryFailureText.value = 'Failed to generate summary'
            break;
        case SessionStateFailure.Transcript:
            summaryFailureText.value = 'Failed to generate transcript - please try to regenerate it.'
            break;
        case SessionAbortState.AudioTooShort:
            summaryFailureText.value = 'Audio recorded was less than 20 seconds. Please try to record again.'
            break;
        case SessionAbortState.NonMedicalConversation:
            summaryFailureText.value = 'Something went wrong. Please ensure that the audio is audible and try to regenerate the transcript. Otherwise please contact us.'
            break;
    }
    pendingSummary.value = false;
    summaryFailure.value = true;

}

watch(session, (newSession) => {
    try {
        if (props.templateChanged) {
            tempNewSections.value = [];
        }
        if (!session.value.audioPath && !session.value.liveTranscript && !session.value.transcript) {
            pendingSummary.value = false;
            summaryFailure.value = true;
            return;
        }
        defineSummaryState(newSession);
    } catch (e) {
        emit('show-old-clinical-note');
    }
});

watch(() => props.searching, (newVal) => {
    openSearch.value = newVal;
});


const generating = ref(false);

const generatedSummaries = ref({})

const generate = async (prompt, callback) => {
    generating.value = true;
    const summaryHtml = document.getElementById('editor_' + currentIndex.value).innerHTML
    const summary = stripHtml(summaryHtml)

    const regenerateSectionSummary = httpsCallable(functions, 'regenerateSectionSummary');
    const result = await regenerateSectionSummary({ prompt: prompt, summary: summaryHtml, transcript: session.value.transcript })

    const response = result.data.response
    const cleanResponse = response.replace('Summary: ', '')

    if (!generatedSummaries.value[currentIndex.value]) {
        generatedSummaries.value[currentIndex.value] = { history: [summaryHtml], current: 0 }
    }
    generatedSummaries.value[currentIndex.value].history.push(cleanResponse)
    generatedSummaries.value[currentIndex.value].current = generatedSummaries.value[currentIndex.value].history.length - 1

    //set element the response
    document.getElementById('editor_' + currentIndex.value).innerHTML = cleanResponse

    generating.value = false;

    getTempEditedSections();
    trackEvent(NOTE_SECTION_REGENERATE, { userId: user.value.id, userEmail: user.value.email, sessionId: props.id, prompt, summary: summaryHtml, response: cleanResponse });
    callback()
}

const undo = () => {
    if (generatedSummaries.value[currentIndex.value].current > 0) {
        generatedSummaries.value[currentIndex.value].current--
        document.getElementById('editor_' + currentIndex.value).innerHTML = generatedSummaries.value[currentIndex.value].history[generatedSummaries.value[currentIndex.value].current]
        getTempEditedSections();
    }
}

const redo = () => {
    if (generatedSummaries.value[currentIndex.value].current < generatedSummaries.value[currentIndex.value].history.length - 1) {
        generatedSummaries.value[currentIndex.value].current++
        document.getElementById('editor_' + currentIndex.value).innerHTML = generatedSummaries.value[currentIndex.value].history[generatedSummaries.value[currentIndex.value].current]
        getTempEditedSections();
    }
}

watch(() => props.copyTrigger, (newVal) => {
    copyToClipboard();
});

watch(() => props.sendEhr, (newVal) => {
    sendMessageToExtension(clinicalNoteSections.value);
});

// const localEditing = ref(false)
// watch(() => props.editing, (newVal) => {
//     console.log("XAXAX")
//     localEditing.value = newVal;
// });

const copyToClipboard = async () => {
    try {
        const hasUnsavedChanges = tempNewSections.value.length > 0;
        const sections = hasUnsavedChanges ? tempNewSections.value : clinicalNoteSections.value;
        const formattedHtml = sections.map(section => `
            <div class="copy-section">
                <h3>${section.section}</h3>
                <div style="white-space: pre-wrap; display: block;">${section.content.replace(/\n/g, '<br>')}</div>
            </div>
        `).join('');

        const blob = new Blob([parseContentForTimestamps(formattedHtml)], { type: 'text/html' });
        const data = new ClipboardItem({
            'text/html': blob,
            'text/plain': new Blob([stripSectionHtml(parseContentForTimestamps(formattedHtml))], { type: 'text/plain' })
        });

        await navigator.clipboard.write([data]);

        snackbar.value = true;
        snackbarText.value = 'Note copied to clipboard';
    } catch (err) {
        console.error('Failed to copy: ', err);
        snackbar.value = true;
        snackbarText.value = 'Failed to copy note';
    }
};

const copyNoteSection = (index) => {
    try {
        if (props.disabledCopy) {
            emit('show-price-banner');
            return;
        }

        const currentSections = tempNewSections.value.length > 0 ? tempNewSections.value : clinicalNoteSections.value;


        nextTick(async () => {
            const formattedHtml =
                `
                <h3>${currentSections[index].section}</h3>
                <div style="white-space: pre-wrap; display: block;">${currentSections[index].content?.replace(/\n/g, '<br>')}</div>
            `
            const blob = new Blob([parseContentForTimestamps(formattedHtml)], { type: 'text/html' });
            const data = new ClipboardItem({
                'text/html': blob,
                'text/plain': new Blob([stripSectionHtml(parseContentForTimestamps(formattedHtml))], { type: 'text/plain' })
            });


            await navigator.clipboard.write([data]);
            snackbar.value = true;
            snackbarText.value = 'Note copied to clipboard';
            trackEvent(NOTE_SECTION_COPY, { userId: user.value.id, userEmail: user.value.email });
        });

    } catch (err) {
        console.error('Failed to copy: ', err);
        snackbar.value = true;
        snackbarText.value = 'Failed to copy note';
    }
}

const showPriceBanner = () => {
    emit('show-price-banner');
}

const parseContentForTimestamps = content => {
    const regex = /_PARSE_THIS_DATE(.*?)PARSE_THIS_DATE_/g;
    return content.replace(regex, (_, timestamp) => moment(timestamp).format("MM/DD/YYYY HH:mm"));
}

const handleBeforeUnload = (e) => {
    if (isPendingSave.value) {
        e.preventDefault();
    }
};

const blockNavigation = (to, from, next) => {
    if (isPendingSave.value) {
        if (window.confirm('Changes you made may not be saved. Are you sure you want to leave?')) {
            next();
        } else {
            next(false);
        }
    } else {
        next();
    }
};

onMounted(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    if (router) {
        router.beforeEach(blockNavigation);
    }
});

onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    if (router) {
        router.beforeEach((to, from, next) => next());
    }

});

</script>

<style scoped>
.non-selectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: not-allowed;
}

.clinical-note-card {
    cursor: pointer;
}

.v-tooltip span {
    color: white !important;
}

.clinical-note-actions {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
}

.search-replace-wrapper {
    display: flex;
    justify-content: end;

}

.search-replace-card {
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items: self-end;
    font-size: 0.9rem;
}

.progress-spinner {
    color: var(--bittersweet)
}

@media (max-width: 1200px) {
    .search-replace-card {
        width: 100%;
    }
}

.editor {
    cursor: pointer
}

.editorContainerSelected {
    outline: 1px solid var(--melon);
}

.noteActions {
    display: none;
}

.editorContainer:hover .noteActions {
    display: block;
}

#editor {
    outline: none;
    border: none;

}

#editor:focus {
    outline: none;
    border: none;
}

h3,
p,
li {
    color: black;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.625rem;
}

[contenteditable] {
    outline: none;
}

.editableContent,
::v-deep .editableContent div,
::v-deep .editableContent p {
    color: #5e6576 !important;
    font: 400 1rem / 1.625rem "Open Sans", sans-serif !important;
    margin-bottom: 0;
}

.editableContent {
    -webkit-user-modify: read-write-plaintext-only;
    -webkit-user-select: text;
    user-select: text;
    white-space: pre-wrap;
}

.editableContent * {
    background: inherit !important;
    color: inherit !important;
    font-family: inherit !important;
}
</style>
