<template>
    <div class="d-flex flex-column align-center">
        <h3>Loading your Questionnaire</h3>
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { getTokenFromShortId } from '@/composables/useToken'
import { trackEvent, AGENT_QUESTIONNAIRE_REDIRECTED, AGENT_QUESTIONNAIRE_REDIRECTED_ERROR } from '@/utilities/analyticsService';

onMounted(() => {
    const route = useRoute()
    const router = useRouter()

    getTokenFromShortId(route.params.short)
        .then(res => {
            trackEvent(AGENT_QUESTIONNAIRE_REDIRECTED, { token: res })
            router.push({
                name: 'QuestionnaireClient',
                query: {
                    token: res.token
                }
            })
        })
        .catch(err => {
            trackEvent(AGENT_QUESTIONNAIRE_REDIRECTED_ERROR, { error: JSON.stringify(err) })
            console.log(err)
        })

})
</script>