<template>
    <div class="leads-container">
        <div class="d-flex justify-space-between align-center mb-4 px-4">
            <h6 class="text-h6 mb-0">Leads Management</h6>
            <div class="d-flex">
                <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Search" single-line hide-details
                    density="compact" variant="outlined" class="mr-2" style="width: 250px;"></v-text-field>
                <v-btn color="bittersweet" @click="addLeadDrawer = true">
                    <template v-slot:prepend>
                        <v-icon color="white">mdi-plus</v-icon>
                    </template>
                    <span class="btnText">Add Lead</span>
                </v-btn>
            </div>
        </div>

        <!-- Error Alert -->
        <v-alert v-if="error" type="error" class="mx-4 mb-4">
            {{ error }}
        </v-alert>

        <v-card class="mx-4" elevation="0">
            <v-data-table :headers="headers" :items="leads" :search="search" :loading="loading" class="leads-table"
                item-value="id">
                <template v-slot:no-data>
                    <div class="d-flex flex-column align-center pa-4">
                        <v-icon icon="mdi-account-search" size="large" color="grey-lighten-1" class="mb-2"></v-icon>
                        <span class="text-grey-darken-1">No leads found</span>
                    </div>
                </template>

                <template v-slot:item.status="{ item }">
                    <v-chip :color="getStatusColor(item.status)" size="small" class="text-caption">
                        {{ item.status }}
                    </v-chip>
                </template>

                <template v-slot:item.stage="{ item }">
                    <v-select v-model="item.stage" :items="stageOptions" item-title="text" item-value="value"
                        density="compact" variant="outlined" hide-details class="stage-select"
                        :loading="updatingStages[item.id]" @focus="captureOriginalStage(item)"
                        @update:model-value="updateLeadStage(item)">
                        <template v-slot:selection="{ item }">
                            <v-chip :color="getStageColor(item.value)" size="small" class="text-caption">
                                {{ formatStage(item.value) }}
                            </v-chip>
                        </template>
                    </v-select>
                </template>

                <template v-slot:item.source="{ item }">
                    {{ item.source || '-' }}
                </template>

                <template v-slot:item.notes="{ item }">
                    <v-tooltip location="top" max-width="300">
                        <template v-slot:activator="{ props }">
                            <div v-bind="props" class="notes-cell text-truncate" style="max-width: 150px;">
                                {{ item.notes || '-' }}
                            </div>
                        </template>
                        <span class="text-white">{{ item.notes || 'No notes available' }}</span>
                    </v-tooltip>
                </template>

                <template v-slot:item.date="{ item }">
                    {{ formatDate(item.createdAt || item.date) }}
                </template>

                <template v-slot:item.conversation="{ item }">
                    <v-btn color="primary" size="small" variant="text" @click="navigateToConversation(item)">
                        <v-icon size="small" class="mr-1">mdi-chat</v-icon>
                        Conversation
                    </v-btn>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-btn icon="mdi-pencil" variant="text" size="small" color="primary"
                        @click="editLead(item)"></v-btn>
                    <v-btn icon="mdi-delete" variant="text" size="small" color="error"
                        @click="confirmDelete(item)"></v-btn>
                </template>
            </v-data-table>
        </v-card>

        <!-- Add Lead Drawer -->
        <v-navigation-drawer v-model="addLeadDrawer" location="right" temporary width="400" class="add-lead-drawer">
            <v-card flat class="h-100 d-flex flex-column">
                <v-card-title class="d-flex align-center pa-4 pb-2 text-start">
                    <v-icon :icon="isEditing ? 'mdi-account-edit' : 'mdi-account-plus'" color="bittersweet"
                        class="mr-2"></v-icon>
                    <span class="text-h6">{{ isEditing ? 'Edit Lead' : 'Add New Lead' }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon="mdi-close" variant="text" size="small" @click="closeDrawerWithConfirmation"></v-btn>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pa-4 text-start overflow-y-auto flex-grow-1">
                    <v-form ref="leadForm" v-model="isFormValid" @submit.prevent="saveLead">
                        <v-row dense>
                            <v-col cols="12">
                                <v-text-field v-model="newLead.patientName" label="Full Name" variant="outlined"
                                    density="comfortable" :rules="[v => isEditing ? true : (!!v || 'Name is required')]"
                                    :required="!isEditing"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field v-model="newLead.email" label="Email" variant="outlined"
                                    density="comfortable" :rules="[
                                        v => isEditing ? true : (!!v || 'Email is required'),
                                        v => isEditing ? true : (/.+@.+\..+/.test(v) || 'Email must be valid')
                                    ]" :required="!isEditing"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field v-model="newLead.phoneNumber" label="Phone Number" variant="outlined"
                                    density="comfortable"
                                    :rules="[v => isEditing ? true : (!!v || 'Phone number is required')]"
                                    :required="!isEditing"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-select v-model="newLead.status" label="Status" :items="['lead', 'patient']"
                                    variant="outlined" density="comfortable"></v-select>
                            </v-col>

                            <v-col cols="12">
                                <v-select v-model="newLead.stage" label="Stage" item-title="text" item-value="value"
                                    :return-object="false" :items="stageOptions" variant="outlined"
                                    density="comfortable"></v-select>
                            </v-col>

                            <v-col cols="12">
                                <v-select v-model="newLead.source" label="Source"
                                    :items="['Sarah', 'Website', 'Referral', 'Social Media', 'Email Campaign', 'Phone Inquiry', 'Other']"
                                    variant="outlined" density="comfortable"></v-select>
                            </v-col>

                            <v-col cols="12">
                                <v-textarea v-model="newLead.notes" label="Notes" variant="outlined" rows="3" auto-grow
                                    density="comfortable"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions class="pa-4 pt-0">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="text" @click="closeDrawerWithConfirmation">Cancel</v-btn>
                    <v-btn color="bittersweet" variant="flat" :loading="saveLoading"
                        :disabled="(!isEditing && !isFormValid) || saveLoading" @click="saveLead">
                        <span class="btnText">{{ isEditing ? 'UPDATE LEAD' : 'SAVE LEAD' }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-navigation-drawer>

        <!-- Delete Confirmation Drawer -->
        <v-navigation-drawer v-model="deleteDrawer" location="right" temporary width="350" class="delete-drawer">
            <v-card flat class="h-100">
                <v-card-title class="d-flex align-center pa-4 pb-2 text-start">
                    <v-icon icon="mdi-alert-circle" color="error" class="mr-2"></v-icon>
                    <span class="text-h6">Delete Lead</span>
                    <v-spacer></v-spacer>
                    <v-btn icon="mdi-close" variant="text" size="small" @click="deleteDrawer = false"></v-btn>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pa-4 text-start">
                    <p class="mb-4">Are you sure you want to delete this lead?</p>

                    <v-list v-if="leadToDelete" density="compact" class="bg-grey-lighten-5 rounded mb-4">
                        <v-list-item>
                            <v-list-item-title class="font-weight-medium">{{ leadToDelete.patientName
                                }}</v-list-item-title>
                            <v-list-item-subtitle v-if="leadToDelete.email">{{ leadToDelete.email
                                }}</v-list-item-subtitle>
                        </v-list-item>
                    </v-list>

                    <p class="text-caption text-grey-darken-1">This action cannot be undone.</p>
                </v-card-text>

                <v-card-actions class="pa-4 pt-0">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="text" @click="deleteLead">Delete</v-btn>
                    <v-btn color="bittersweet" variant="flat" @click="deleteDrawer = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-navigation-drawer>

        <!-- Success Snackbar -->
        <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="6000">
            <span :class="snackbarColor === 'white' ? 'text-black' : ''">{{ snackbarText }}</span>
            <template v-slot:actions>
                <v-btn :color="snackbarColor === 'white' ? 'black' : 'white'" variant="text"
                    @click="snackbar = false">Close</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, reactive, inject, watch } from 'vue';
import { Timestamp } from "@firebase/firestore";
import moment from 'moment-timezone';
import { getUserObject } from '@/composables/getUserObject';
import getLeads from '@/composables/getLeads';
import { projectFirestore } from '@/firebase/config';

// Get parent tab state using provide/inject pattern
const setActiveTab = inject('setActiveTabWithPatient', null);

// Table configuration
const headers = [
    { title: 'Name', key: 'patientName', align: 'start', sortable: true },
    { title: 'Email', key: 'email', align: 'start', sortable: true },
    { title: 'Phone', key: 'phoneNumber', align: 'start', sortable: true },
    { title: 'Status', key: 'status', align: 'start', sortable: true },
    { title: 'Stage', key: 'stage', align: 'start', sortable: true },
    { title: 'Source', key: 'source', align: 'start', sortable: true },
    { title: 'Notes', key: 'notes', align: 'start', sortable: true },
    { title: 'Date', key: 'date', align: 'start', sortable: true },
    { title: 'Conversation', key: 'conversation', align: 'center', sortable: false },
    { title: 'Actions', key: 'actions', sortable: false, align: 'end' }
];

// Data
const search = ref('');
const loading = ref(true);
const leads = ref([]);
const deleteDrawer = ref(false);
const addLeadDrawer = ref(false);
const leadToDelete = ref(null);
const userObject = ref(null);
const error = ref(null);
const leadForm = ref(null);
const isFormValid = ref(false);
const saveLoading = ref(false);
const updatingStages = ref({}); // Track which leads are currently updating
const snackbar = ref(false);
const snackbarText = ref('');
const snackbarColor = ref('white');

// New lead form data
const newLead = reactive({
    patientName: '',
    email: '',
    phoneNumber: '',
    status: 'lead',
    stage: 'new_lead',
    source: 'Website',
    notes: '',
});

// Track if we're editing an existing lead or adding a new one
const isEditing = ref(false);
const leadIdToEdit = ref(null);

// Watch stage changes in the form to update status automatically
watch(() => newLead.stage, (newStage) => {
    if (newStage === 'scheduled_evaluation' && newLead.status === 'lead') {
        newLead.status = 'patient';
    }
});

// Stage options with formatted display text
const stageOptions = computed(() => {
    const stages = [
        'new_lead',
        'scheduled_evaluation',
        'unable_to_schedule'
    ];

    return stages.map(stage => ({
        value: stage,
        text: formatStage(stage)
    }));
});

// Reset new lead form
const resetLeadForm = () => {
    newLead.patientName = '';
    newLead.email = '';
    newLead.phoneNumber = '';
    newLead.status = 'lead';
    newLead.stage = 'new_lead';
    newLead.source = 'Website';
    newLead.notes = '';

    // Reset editing state
    isEditing.value = false;
    leadIdToEdit.value = null;

    // Reset validation
    if (leadForm.value) {
        leadForm.value.resetValidation();
    }
};

// Navigation function for conversation button
const navigateToConversation = (item) => {
    // Switch to phone_calls tab and pass the lead info
    if (setActiveTab) {
        setActiveTab('phone_calls', {
            id: item.id,
            name: item.patientName,
            email: item.email,
            phone: item.phoneNumber
        });
    } else {
        // Fallback if inject is not available
        console.warn('setActiveTabWithPatient function not available via inject');
    }
};

// Fetch real lead data
onMounted(async () => {
    // Get user object
    userObject.value = await getUserObject();
    console.log('User clinicId:', userObject.value.clinicId);

    // Check if we have a clinicId before trying to fetch leads
    if (userObject.value && userObject.value.clinicId) {
        try {
            // Fetch leads data using the composable
            const { leads: fetchedLeads, error: fetchError, loading: fetchLoading } = await getLeads(userObject.value.clinicId);

            // Update the component's reactive references
            leads.value = fetchedLeads.value;
            error.value = fetchError.value;
            loading.value = fetchLoading.value;

            // Ensure all leads have a valid stage
            const validStages = ['new_lead', 'scheduled_evaluation', 'unable_to_schedule'];
            leads.value.forEach(lead => {
                if (!validStages.includes(lead.stage)) {
                    lead.stage = 'new_lead';
                }
            });

            console.log('Fetched leads:', leads.value);
        } catch (err) {
            console.error('Error fetching leads:', err);
            error.value = 'Failed to load leads data';
            loading.value = false;
        }
    } else {
        console.error('No clinicId available to fetch leads');
        error.value = 'Unable to identify clinic';
        loading.value = false;
    }
});

// Close drawer handler with confirmation
const closeDrawerWithConfirmation = () => {
    // Immediately close the drawer without confirmation
    addLeadDrawer.value = false;
};

// Watch the drawer state to reset the form when closed
watch(addLeadDrawer, (newVal) => {
    if (!newVal) { // When drawer is closed
        // Delay the form reset to allow for closing animation
        setTimeout(() => {
            resetLeadForm();
        }, 300);
    }
});

// Methods
const formatDate = (date) => {
    if (!date) return '';

    // Handle both Firestore Timestamp objects and our mock data format
    if (date instanceof Timestamp) {
        return moment(date.toDate()).format('MMM D, YYYY');
    } else if (date.seconds) {
        return moment(new Timestamp(date.seconds, date.nanoseconds || 0).toDate()).format('MMM D, YYYY');
    }

    return moment(date).format('MMM D, YYYY');
};

const getStatusColor = (status) => {
    const statusColors = {
        'lead': 'info',
        'patient': 'success'
    };
    return statusColors[status] || 'grey';
};

const getStageColor = (stage) => {
    const stageColors = {
        'new_lead': 'blue-lighten-3',
        'scheduled_evaluation': 'deep-purple-lighten-1',
        'unable_to_schedule': 'red-lighten-1'
    };
    return stageColors[stage] || 'grey';
};

const formatStage = (stage) => {
    if (!stage) return '';
    // Convert snake_case to Title Case with spaces
    return stage.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const editLead = (item) => {
    // Set editing mode
    isEditing.value = true;
    leadIdToEdit.value = item.id;

    // Populate the form with the lead's data
    newLead.patientName = item.patientName || '';
    newLead.email = item.email || '';
    newLead.phoneNumber = item.phoneNumber || '';
    newLead.status = item.status || 'lead';

    // Make sure we use a valid stage from our current options
    const validStages = ['new_lead', 'scheduled_evaluation', 'unable_to_schedule'];
    newLead.stage = validStages.includes(item.stage) ? item.stage : 'new_lead';

    newLead.source = item.source || 'Website';
    newLead.notes = item.notes || '';

    // Open the drawer
    addLeadDrawer.value = true;
};

const confirmDelete = (item) => {
    leadToDelete.value = item;
    deleteDrawer.value = true;
};

const deleteLead = async () => {
    if (!leadToDelete.value) return;

    try {
        // Delete the lead document from Firestore
        await projectFirestore.collection('patients').doc(leadToDelete.value.id).delete();

        // Remove the lead from the local array
        leads.value = leads.value.filter(lead => lead.id !== leadToDelete.value.id);

        // Close the drawer
        deleteDrawer.value = false;
        leadToDelete.value = null;

        // Show success message (you can add a toast/notification here if desired)
        console.log('Lead deleted successfully');
    } catch (err) {
        console.error('Error deleting lead:', err);
        error.value = 'Failed to delete lead';
    }
};

const saveLead = async () => {
    // Skip validation check when editing, only check when adding new lead
    if (!isEditing.value && !isFormValid.value) return;

    try {
        saveLoading.value = true;

        // Make sure we have a clinic ID
        if (!userObject.value || !userObject.value.clinicId) {
            throw new Error('No clinic ID available');
        }

        // Prepare the lead data
        const leadData = {
            ...newLead,
            clinicId: userObject.value.clinicId,
            updatedAt: Timestamp.now()
        };

        // If we're creating a new lead, add createdAt timestamp
        if (!isEditing.value) {
            leadData.createdAt = Timestamp.now();
        }

        if (isEditing.value && leadIdToEdit.value) {
            // Update existing lead in Firestore
            await projectFirestore.collection('patients').doc(leadIdToEdit.value).update(leadData);

            // Update the lead in the local array
            const index = leads.value.findIndex(lead => lead.id === leadIdToEdit.value);
            if (index !== -1) {
                leads.value[index] = {
                    ...leads.value[index],
                    ...leadData,
                    id: leadIdToEdit.value
                };
            }

            console.log('Lead updated successfully');
        } else {
            // Save new lead to Firestore
            const docRef = await projectFirestore.collection('patients').add(leadData);

            // Add the new lead to the local array with its ID
            leads.value.unshift({
                id: docRef.id,
                ...leadData
            });

            console.log('Lead created successfully');
        }

        // Close the drawer and reset the form
        addLeadDrawer.value = false;
        resetLeadForm();

    } catch (err) {
        console.error(isEditing.value ? 'Error updating lead:' : 'Error creating lead:', err);
        error.value = `Failed to ${isEditing.value ? 'update' : 'create'} lead: ${err.message}`;
    } finally {
        saveLoading.value = false;
    }
};

const updateLeadStage = async (item) => {
    if (!item || !item.id) return;

    try {
        // Set updating state for this lead
        updatingStages.value[item.id] = true;

        // Prepare the update data
        const updateData = {
            stage: item.stage,
            updatedAt: Timestamp.now()
        };

        // If the stage is changed to scheduled_evaluation, automatically change status to Patient
        if (item.stage === 'scheduled_evaluation' && item.status === 'lead') {
            updateData.status = 'patient';
            item.status = 'patient'; // Update in local data too
        }

        // Update in Firestore
        await projectFirestore.collection('patients').doc(item.id).update(updateData);

        // Log the updated data for debugging
        console.log('Updated lead in Firestore with data:', updateData);

        // Verify the update by reading back the document
        const updatedDoc = await projectFirestore.collection('patients').doc(item.id).get();
        console.log('Updated document from Firestore:', updatedDoc.data());

        console.log(`Stage updated to ${item.stage} for lead ${item.patientName}`);

        // Show success notification
        snackbarColor.value = 'white';
        let successMessage = `Stage updated to "${formatStage(item.stage)}" for ${item.patientName}`;

        // Add note about status change if applicable
        if (updateData.status) {
            successMessage += ` and status changed to Patient`;
        }

        snackbarText.value = successMessage;
        snackbar.value = true;
    } catch (err) {
        console.error('Error updating lead stage:', err);

        // Show error notification
        snackbarColor.value = 'error';
        snackbarText.value = 'Failed to update stage: ' + err.message;
        snackbar.value = true;

        // Revert the change in the local data if there was an error
        const originalLead = leads.value.find(lead => lead.id === item.id);
        if (originalLead && originalLead._originalStage) {
            item.stage = originalLead._originalStage;
            // Also revert status if it was changed
            if (originalLead._originalStatus) {
                item.status = originalLead._originalStatus;
            }
        }
    } finally {
        // Clear the updating state
        updatingStages.value[item.id] = false;
    }
};

const captureOriginalStage = (item) => {
    // Store the original stage and status values in case we need to roll back after an error
    if (item) {
        if (!item._originalStage) {
            item._originalStage = item.stage;
        }
        if (!item._originalStatus) {
            item._originalStatus = item.status;
        }
    }
};
</script>

<style scoped>
.leads-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    box-sizing: border-box;
}

.leads-table {
    width: 100%;
    border-radius: 8px;
}

.leads-table :deep(th) {
    font-weight: 600;
    font-size: 0.85rem;
    color: #555;
    background-color: #f5f5f5;
    text-align: left;
}

.leads-table :deep(td) {
    font-size: 0.9rem;
    text-align: left;
}

.delete-drawer :deep(.v-navigation-drawer__scrim),
.add-lead-drawer :deep(.v-navigation-drawer__scrim) {
    opacity: 0.5;
}

.delete-drawer :deep(.v-card),
.add-lead-drawer :deep(.v-card) {
    display: flex;
    flex-direction: column;
}

.btnText {
    text-decoration: none;
    text-transform: none;
    color: white;
}

.stage-select {
    min-width: 180px;
}

.stage-select :deep(.v-field__input) {
    min-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
}

.stage-select :deep(.v-field__append-inner) {
    padding-top: 4px;
}

.stage-select :deep(.v-select__selection) {
    margin-top: 0;
    margin-bottom: 0;
}

.notes-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
</style>