import { getStorage, ref, getDownloadURL } from "firebase/storage";

async function getInsuranceImageUrl(path) {
    const storage = getStorage();

    const storageRef = ref(storage, path);

    try {
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        console.error(`Got error while trying to get public url for the insurance image ${path}...`, error);
        throw new Error('Insurance image not found');
    }

}

export default getInsuranceImageUrl;