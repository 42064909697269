<template>
    <div class="coordinator-container">
        <div class="ma-4">
            <h5 class="text-h5 mb-0 text-left">AI Care Coordinator</h5>
            <p class="text-left text-body-2">Review and manage patient intake interactions handled by Sarah.</p>
        </div>

        <v-card elevation="0" class="coordinator-card">
            <v-tabs v-model="activeTab" slider-color="var(--bittersweet)" class="coordinator-tabs">
                <v-tab value="phone_calls">Phone Calls</v-tab>
                <v-tab value="leads">Leads</v-tab>
            </v-tabs>

            <v-window v-model="activeTab" class="coordinator-window">
                <v-window-item value="phone_calls">
                    <PhoneCalls :selectedPatient="selectedPatient" @patientFilterCleared="clearSelectedPatient" />
                </v-window-item>

                <v-window-item value="leads">
                    <LeadsView />
                </v-window-item>
            </v-window>
        </v-card>
    </div>
</template>

<script setup>
import PhoneCalls from '@/components/ai-coordinator/PhoneCalls.vue';
import LeadsView from '@/components/ai-coordinator/LeadsView.vue';
import { ref, provide, watch } from 'vue';

const activeTab = ref('phone_calls');
const selectedPatient = ref(null);

// Provide the setActiveTab function to child components
const setActiveTabWithPatient = (tab, patient = null) => {
    activeTab.value = tab;
    console.log('setActiveTabWithPatient:', tab);
    // Update selected patient information if provided
    if (patient) {
        selectedPatient.value = patient;
        console.log('Set selected patient:', patient.id);
    } else {
        // Reset the selected patient when switching tabs without a patient
        selectedPatient.value = null;
    }
};

// Clear selected patient when filter is cleared from PhoneCalls component
const clearSelectedPatient = () => {
    selectedPatient.value = null;
    console.log('Cleared patient filter');
};

provide('setActiveTabWithPatient', setActiveTabWithPatient);

// Watch for tab changes to potentially reset selection
watch(activeTab, (newTab) => {
    console.log('activeTab changed to:', newTab);
    if (newTab === 'leads') {
        // If we're switching to leads tab, track that we had a selected patient
        // but don't clear it yet so we can restore it if they go back to phone_calls
    }
});
</script>

<style scoped>
.coordinator-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden;
}

.coordinator-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.coordinator-tabs {
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
}

.coordinator-window {
    flex: 1;
    overflow: hidden;
}

.coordinator-tabs :deep(.v-tab--selected span) {
    color: var(--bittersweet);
    font-size: medium;
}
</style>