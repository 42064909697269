<template>
    <div>
      <v-card elevation="0" class="rounded-card d-flex flex-column py-4 px-5">
        <div class="d-flex">
          <div class="text-h6 text-left">Documents</div>
          <v-btn class="ml-auto" variant="text" @click="openDrawer">
            <span style="color:var(--bittersweet); text-transform: none"> + Upload Document </span>
          </v-btn>
        </div>
  
        <div v-if="uploadingDocument" class="d-flex align-center justify-center my-5">
          <v-progress-circular class="mr-1" indeterminate :rotate="90" :size="20" :width="2"></v-progress-circular>
          <p class="mb-0 ml-3">Uploading document...</p>
        </div>
  
        <v-data-table
            v-else-if="documents.length" :headers="headers" :items="documents" :items-per-page.sync="itemsPerPage"
            @update:items-per-page="(value) => (itemsPerPage = value)" item-key="id" class="documents-table" >
            <template v-slot:item="{ item }">
                <tr class="table-row" @click="viewDocument(item)">
                <td class="text-left">{{ item.name }}</td>
                <td class="text-center">
                    <div v-if="deletingDocuments[item.id]" class="d-flex justify-center">
                    <v-progress-circular :size="20" :width="2" indeterminate color="primary"> </v-progress-circular>
                    </div>
                    <v-icon v-else @click.stop="deleteDocument(item)">mdi-trash-can-outline</v-icon>
                </td>
                </tr>
            </template>
        </v-data-table>
  
        <div v-else class="my-5 text-left">
          No Documents uploaded
        </div>
      </v-card>
      
      <v-navigation-drawer v-model="drawer" temporary location="right" :width="800">
          <div class="d-flex flex-column pa-10 text-left">
              <h4>Upload Document for {{ getPatientFullName(patient) }}</h4>
              <div class="upload-area mt-5" @click="triggerFileInput" 
                :class="{ 'has-file': file, 'drag-over': isDragging }"
                @dragover.prevent="isDragging = true"
                @dragleave.prevent="isDragging = false"
                @drop.prevent="onFileDrop">
                <div class="upload-content">
                    <v-icon size="48" color="grey lighten-1">mdi-cloud-upload-outline</v-icon>
                    <div class="upload-text mt-2">
                    <template v-if="!file">
                        <p class="text-body-1">Drag and drop your file here</p>
                        <p class="text-body-2 text-grey">or</p>
                        <p class="text-body-1">Click to browse files</p>
                        <p class="text-caption text-grey mt-2">
                            Allowed file types: PDFs, Word documents, Excel spreadsheets, text files, and images
                        </p>
                        <p class="text-caption text-grey">
                            Maximum file size: 25 MB
                        </p>
                    </template>
                    <template v-else>
                        <p class="text-body-1">{{ file.name }}</p>
                        <p class="text-caption text-grey">{{ formatFileSize(file.size) }}</p>
                        <v-btn color="primary" variant="outlined" size="small" class="mt-2" @click.stop="removeFile">
                        Remove file
                        </v-btn>
                    </template>
                    </div>
                </div>
              </div>
              
              <input type="file" ref="fileInput" class="d-none" @change="onFileSelected"
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                text/plain,text/csv,image/jpeg,image/png"/>
              
              <div class="d-flex justify-end mt-5">
              <v-btn variant="text" @click="cancelUpload">
                  <span style="color:var(--bittersweet); text-transform: none"> Cancel </span>
              </v-btn>
              <v-btn elevation="0" width="auto" class="mdhub-btn ml-3" @click="uploadDocument"
                  :disabled="!file"> Submit </v-btn>
              </div>
          </div>
      </v-navigation-drawer>
  
      <v-snackbar v-model="errorSnackbar" :timeout="2000" color="bittersweet">
        <span style="color:white;">{{ errorMessage }}</span>
      </v-snackbar>
  
      <v-snackbar v-model="successSnackbar" :timeout="2000" color="white">
        <span style="color:var(--bittersweet); text-transform: none">{{ successMessage }}</span>
      </v-snackbar>
  
      <v-dialog v-model="docDialog" max-width="800px">
        <v-card>
          <v-card-title class="d-flex flex-column align-start px-6 pt-6 pb-2">
            <h4 class="text-h5 font-weight-medium">Document Preview</h4>
            <span class="text-subtitle-1 text-grey-darken-1">{{ selectedDoc?.name }}</span>
          </v-card-title>
          <v-card-text>
            <template v-if="isImage(selectedDoc)">
              <v-img :src="docUrl" max-width="100%"></v-img>
            </template>
            <template v-else-if="isPdf(selectedDoc)">
              <iframe :src="docUrl" width="100%" height="500px" frameborder="0"></iframe>
            </template>
            <template v-else>
              <div class="pa-4 text-center">
                <v-icon size="48" color="grey lighten-1">mdi-file-document-outline</v-icon>
                <p class="mt-2">This file type cannot be previewed directly.</p>
              </div>
            </template>
          </v-card-text>
          <v-card-actions class="px-6 py-4">
            <v-spacer></v-spacer>
            <v-btn text @click="docDialog = false" class="mr-2">
                <span style="color:var(--bittersweet); text-transform: none">Close</span>
            </v-btn>
            <v-btn elevation="0" width="auto" class="mdhub-btn ml-3" :href="docUrl" target="_blank">
                {{ isImage(selectedDoc) || isPdf(selectedDoc) ? 'Open in New Tab' : 'Download File' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>
  
<script setup>
import { ref, defineProps, onMounted, reactive, watch } from 'vue';
import { useRouter } from 'vue-router';
import { trackEvent, DOCUMENT_UPLOAD, DOCUMENT_UPLOAD_ERROR, DOCUMENT_DELETE, DOCUMENT_DELETE_ERROR } from '@/utilities/analyticsService';
import getUser from '@/composables/getUser';
import { getPatientFullName } from '@/utilities/patientUtils';
import { projectStorage } from "@/firebase/config";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

const { user } = getUser();
const props = defineProps({
    patient: Object
});

const file = ref(null);
const uploadingDocument = ref(false);
const errorSnackbar = ref(false);
const errorMessage = ref("An error occurred.");
const successSnackbar = ref(false);
const successMessage = ref("");
const drawer = ref(false);

const deletingDocuments = reactive({});
const documents = ref([]);

const docDialog = ref(false);
const selectedDoc = ref(null);
const docUrl = ref("");

const isDragging = ref(false);
const fileInput = ref(null);

const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25MB in bytes

// List of accepted types
const acceptedMimeTypes = [
    // PDFs
    'application/pdf',
    // Word
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // Excel
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // Text
    'text/plain',
    'text/csv',
    // Images
    'image/jpeg',
    'image/png'
];

const headers = ref([
  { title: 'Name', key: 'name', align: 'start' },
  { title: 'Delete', key: 'actions', align: 'center', sortable: false }
]);

const itemsPerPage = ref(
  localStorage.getItem('globalItemsPerPage')
    ? JSON.parse(localStorage.getItem('globalItemsPerPage'))
    : 10
);

watch(itemsPerPage, (val) => {
  localStorage.setItem('globalItemsPerPage', JSON.stringify(val));
});
  
const onFileSelected = (event) => {
    const selectedFile = event.target.files[0] || null;
    
    if (selectedFile) {
        if (selectedFile.size > MAX_FILE_SIZE) {
            errorMessage.value = `File is too large. Maximum size is ${formatFileSize(MAX_FILE_SIZE)}.`;
            errorSnackbar.value = true;
            event.target.value = '';
            return;
        }
        file.value = selectedFile;
    }
};
  
const onFileDrop = (event) => {
    isDragging.value = false;
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length) {
        const droppedFile = droppedFiles[0];

        if (droppedFile.size > MAX_FILE_SIZE) {
            errorMessage.value = `File is too large. Maximum size is ${formatFileSize(MAX_FILE_SIZE)}.`;
            errorSnackbar.value = true;
            return;
        }

        if (isValidFileType(droppedFile)) {
            file.value = droppedFile;
        } else {
            errorMessage.value = "Invalid file type. Please upload a supported document format.";
            errorSnackbar.value = true;
        }
    }
};
  
const isValidFileType = (file) => {
    return acceptedMimeTypes.includes(file.type);
};
  
const removeFile = (event) => {
    if (event) event.stopPropagation();
    file.value = null;
    if (fileInput.value) fileInput.value.value = '';
};
  
const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};
  
const openDrawer = () => {
    drawer.value = true;
};
  
const cancelUpload = () => {
    drawer.value = false;
    file.value = null;
};
  
const triggerFileInput = () => {
    fileInput.value.click();
};
  
const uploadDocument = async () => {
    if (!file.value) return;

    const selectedFile = Array.isArray(file.value) ? file.value[0] : file.value;
    if (!selectedFile || !selectedFile.name) {
        console.error("No file name.");
        return;
    }

    if (!isValidFileType(selectedFile)) {
        console.error("Invalid file type.");
        errorMessage.value = "Invalid file type. Please upload a supported document format.";
        errorSnackbar.value = true;
        file.value = null;
        return;
    }

    if (selectedFile.size > MAX_FILE_SIZE) {
        errorMessage.value = `File is too large. Maximum size is ${formatFileSize(MAX_FILE_SIZE)}.`;
        errorSnackbar.value = true;
        file.value = null;
        return;
    }

    uploadingDocument.value = true;
    drawer.value = false;
    const metadata = { contentType: selectedFile.type };

    try {
        // Upload file to storage
        const uniqueName = `${Math.random().toString(36).substring(2, 6)}-${selectedFile.name}`;
        const path = `/patients_docs/${props.patient.userId}/${props.patient.id}/${uniqueName}`;
        const fileStorageRef = projectStorage.ref(path);
        await fileStorageRef.put(selectedFile, metadata);

        // Create document entry in Firestore
        const functions = getFunctions();
        const createDocumentEntryFunc = httpsCallable(functions, 'createPatientDocument');
        
        await createDocumentEntryFunc({
            patientId: props.patient.id,
            name: selectedFile.name,
            path: path,
            contentType: selectedFile.type
        });

        // Refresh document list
        await fetchDocuments();

        // Track event
        trackEvent(DOCUMENT_UPLOAD, { 
            userId: user.value.uid, 
            userEmail: user.value.email, 
            patientId: props.patient.id, 
            file: selectedFile.name
        });

        successMessage.value = "Document uploaded successfully.";
        successSnackbar.value = true;
    } catch (error) {
        console.error('Error uploading document', error);
        errorMessage.value = "Failed to upload document.";
        errorSnackbar.value = true;

        trackEvent(DOCUMENT_UPLOAD_ERROR, {
            userId: user.value.uid,
            userEmail: user.value.email,
            patientId: props.patient.id,
            error: {
                errorName: error.name,
                errorMessage: error.message,
                errorStack: error.stack
            }
        });

    } finally {
        uploadingDocument.value = false;
        file.value = null;
    }
};
  
const fetchDocuments = async () => {
    try {
        const functions = getFunctions();
        const getPatientDocumentsFunc = httpsCallable(functions, 'getPatientDocuments');
        
        const result = await getPatientDocumentsFunc({ patientId: props.patient.id });
        documents.value = result.data.documents || [];
        console.log('Documents fetched successfully:', documents.value);
    } catch (error) {
        console.error("Error fetching documents:", error);
        errorMessage.value = "Failed to load documents.";
        errorSnackbar.value = true;
    }
};
  
onMounted(() => {
    fetchDocuments();
});
  
const isImage = (doc) => {
    if (!doc || !doc.contentType) return false;
    return doc.contentType.startsWith('image/');
};
  
const isPdf = (doc) => {
    if (!doc || !doc.contentType) return false;
    return doc.contentType === 'application/pdf';
};
  
const viewDocument = async (doc) => {
    selectedDoc.value = doc;
    try {
        const fileStorageRef = projectStorage.ref(doc.path);
        docUrl.value = await getDownloadURL(fileStorageRef);
        docDialog.value = true;
    } catch (error) {
        console.error("Error getting download URL", error);
        errorMessage.value = "Failed to retrieve document.";
        errorSnackbar.value = true;
    }
};
  
const deleteDocument = async (doc) => {
    try {
        deletingDocuments[doc.id] = true;
        
        const functions = getFunctions();
        const deleteDocumentFunc = httpsCallable(functions, 'deletePatientDocument');
        
        await deleteDocumentFunc({ documentId: doc.id });
        
        console.log(`Document ${doc.name} deleted successfully`);
        successMessage.value = "Document deleted successfully.";
        successSnackbar.value = true;

        trackEvent(DOCUMENT_DELETE, { 
            userId: user.value.uid, 
            userEmail: user.value.email, 
            patientId: props.patient.id, 
            file: doc.name
        });
        
        await fetchDocuments();
    } catch (error) {
        console.error("Error deleting document", error);
        errorMessage.value = "Failed to delete document.";
        errorSnackbar.value = true;

        trackEvent(DOCUMENT_DELETE_ERROR, {
            userId: user.value.uid,
            userEmail: user.value.email,
            patientId: props.patient.id,
            error: {
                errorName: error.name,
                errorMessage: error.message,
                errorStack: error.stack
            }
        });
    } finally {
        deletingDocuments[doc.id] = false;
    }
};
  
</script>
  
<style scoped>
.table-row {
    cursor: pointer;
    transition: background-color 0.2s ease;
}
.table-row:hover {
    background-color: rgba(var(--v-theme-primary), 0.05) !important;
}
  
.upload-area {
    border: 2px dashed #e0e0e0;
    border-radius: 8px;
    padding: 30px 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
}
  
.upload-area:hover {
    border-color: var(--v-primary-base);
    background-color: rgba(0, 0, 0, 0.02);
}
  
.upload-area.drag-over {
    border-color: var(--v-primary-base);
    background-color: rgba(0, 0, 0, 0.05);
}
  
.upload-area.has-file {
    border-style: solid;
    border-color: var(--v-primary-base);
    background-color: rgba(var(--v-primary-base), 0.05);
}
  
.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mdhub-btn:hover {
  text-decoration: none;
}
</style>