<template>
    <div class="d-flex chat-app-container">
        <div class="chat-list pr-4 rounded-border ml-4" style="width: 40%;">
            <div class="ma-4">
                <div v-if="availableRequestTypes.length > 0">
                    <div class="filter-header">
                        <v-icon icon="mdi-filter-variant" size="small" class="mr-1"></v-icon>
                        <span class="text-subtitle-2 font-weight-medium">Filters</span>
                        <v-spacer></v-spacer>
                        <v-btn variant="text" size="small" density="compact" @click="resetFilters"
                            prepend-icon="mdi-refresh" :disabled="!hasActiveFilters" class="reset-btn">
                            Reset
                        </v-btn>
                    </div>
                    <div class="filters-container">
                        <div class="filter-item">
                            <v-select density="compact" variant="outlined" label="Request type" :items="availableRequestTypes.map(type => ({
                                title: formatRequestType(type),
                                value: type
                            }))" multiple v-model="requestTypeFilters" clearable
                                prepend-inner-icon="mdi-file-document-multiple-outline" class="filter-select"
                                item-title="title" item-value="value" hide-details></v-select>
                        </div>

                        <div class="filter-item">
                            <v-select density="compact" variant="outlined" label="Request status"
                                :items="['COMPLETED', 'PENDING', 'IN_PROGRESS', 'FAILED']" v-model="statusFilter"
                                :disabled="!!statusTaskFilter" key="statusFilter"
                                prepend-inner-icon="mdi-checkbox-marked-circle-outline" class="filter-select"
                                hide-details multiple></v-select>
                        </div>

                        <div class="filter-item">
                            <v-select density="compact" variant="outlined" label="Task status"
                                :items="['OPEN', 'CLOSED']" v-model="statusTaskFilter" clearable
                                :disabled="!!statusFilter" prepend-inner-icon="mdi-format-list-checks"
                                class="filter-select" hide-details></v-select>
                        </div>

                        <div class="filter-item">
                            <v-select density="compact" variant="outlined" label="Order" :items="orders"
                                item-title="text" item-value="val" v-model="order"
                                prepend-inner-icon="mdi-sort-clock-ascending-outline" class="filter-select"
                                hide-details></v-select>
                        </div>
                    </div>
                    <v-divider class="my-3"></v-divider>
                </div>

            </div>
            <v-list class="chat-list-items">
                <v-list-item v-if="conversations.length > 0" v-for="(conversation) in filteredConversations"
                    @click="selectConversation(conversation)"
                    :class="{ 'selected-chat': conversation.id === selectedConversation?.id }" class="mb-2 ">
                    <template v-slot:prepend>
                        <v-avatar :color="conversation.type === 'conversation' ? 'grey-lighten-4' : 'grey-lighten-4'"
                            size="48">
                            <v-icon icon="mdi-phone-outline" v-if="conversation.type === 'conversation'"
                                color="grey-darken-1"></v-icon>
                            <v-icon icon="mdi-file-document-outline" v-else color="grey-darken-1"></v-icon>
                        </v-avatar>
                    </template>
                    <v-list-item-title style="text-align: left;">{{ formatDate(conversation.startedAt ||
                        conversation.createdAt)
                    }}</v-list-item-title>
                    <v-list-item-subtitle style="text-align: left; font-size: 0.75rem; color: #666;">
                        <div class="id-container">
                            <v-btn icon="mdi-content-copy" size="x-small" variant="text"
                                @click.stop="copyToClipboard(conversation.id)" class="copy-id-btn mr-1"
                                density="comfortable">
                            </v-btn>
                            ID: {{ conversation.id }}
                        </div>
                    </v-list-item-subtitle>

                    <template v-slot:append>
                        <div class="mr-3" v-if="conversation.forwarded">

                            <v-tooltip text="Phone forwarded">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" icon="mdi-phone-forward-outline"
                                        color="grey-darken-1"></v-icon>
                                </template>
                            </v-tooltip>
                        </div>
                        <div v-if="conversation.tasks">
                            <v-tooltip :text="defineStatusIndication(conversation).text">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" :color="defineStatusIndication(conversation).color">{{
                                        defineStatusIndication(conversation).icon }}</v-icon>
                                </template>
                            </v-tooltip>
                        </div>

                        <v-list-item-subtitle v-if="!conversation.endedAt && conversation?.type === 'conversation'"
                            style="color:green">
                            <v-icon color="green" size="12" class="pulsing-icon">mdi-circle</v-icon>
                            LIVE
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="userObject?.isAdmin">
                            <v-btn color="grey-lighten-1" icon="mdi-delete" variant="text"
                                @click.stop="selectConversationToDelete(conversation)"></v-btn>
                        </v-list-item-subtitle>
                    </template>
                </v-list-item>
                <div id="load-more-trigger" class="load-more-container">
                    <v-progress-circular v-if="paginationInfo.isLoading" indeterminate></v-progress-circular>
                    <p v-else-if="conversations.length > 0 && !paginationInfo.hasMore"
                        class="text-caption text-center pa-3">
                        End of list
                    </p>
                    <v-btn
                        v-else-if="conversations.length > 0 && paginationInfo.hasMore && !paginationInfo.hasActiveFilters"
                        variant="text" block @click="loadMoreConversations" :disabled="paginationInfo.isLoading"
                        class="mt-2">
                        Load more
                    </v-btn>
                    <p v-else-if="!conversations">
                        <v-progress-circular indeterminate></v-progress-circular>
                    </p>
                    <!-- <p v-else-if="conversations?.length === 0">Empty</p> -->
                </div>
                <v-list-item>
                    <v-progress-circular indeterminate v-if="!conversations"></v-progress-circular>
                    <!-- <p v-else-if="conversations?.length === 0">Empty</p> -->
                </v-list-item>
            </v-list>
        </div>


        <div class="chat-interface pl-4">
            <v-card class="rounded-border" elevation="0" v-if="selectedConversation">
                <v-tabs v-model="tab" slider-color="var(--bittersweet)" class="patient-chart-options">
                    <v-tab value="tasks">Tasks</v-tab>
                    <v-tab value="conversation">Conversation</v-tab>
                    <v-tab value="summary">Report</v-tab>
                </v-tabs>
                <v-window class="window pa-4 mt-4" v-model="tab">
                    <v-window-item value="conversation">
                        <div id="chat" class="chat-messages chat-container">
                            <template v-for="message in interactions">
                                <div :class="['message', message.speaker === 'ai-agent' ? 'bot-message' : 'user-message']"
                                    v-if="message.text">
                                    <v-avatar size="32" class="mr-2 bordered-avatar"
                                        :class="{ 'ml-2': message.speaker == 'Patient' }">
                                        <v-icon :color="message.speaker === 'ai-agent' ? '#B0B0B0' : '#B0B0B0'"
                                            :icon="message.speaker === 'ai-agent' ? 'mdi-phone' : 'mdi-account'">
                                        </v-icon>

                                    </v-avatar>
                                    <div class="message-content">
                                        <p class="message-sender">{{ message.speaker === 'ai-agent' ? 'Sarah' :
                                            'Patient' }}</p>
                                        <p class="message-text">{{ stripTags(message.text) }}</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </v-window-item>
                    <v-window-item value="summary">
                        <div class="report-container mb-6">
                            <div v-if="selectedConversation.type === 'conversation'" class="generate-button-container">
                                <v-btn :loading="generatingSummary" variant="tonal" color="primary"
                                    prepend-icon="mdi-refresh" class="generate-btn"
                                    @click="regenerateSummary(selectedConversation?.id)">
                                    Generate Report
                                </v-btn>
                            </div>
                            <div class="text-left pl-8 summary-text"
                                v-html="formatSummary(selectedConversation.summary)"></div>
                        </div>

                    </v-window-item>
                    <v-window-item value="tasks">
                        <div class="tasks-container" v-if="patientData">
                            <v-card class="patient-info-card mb-4" elevation="0" align="left">
                                <v-card-title class="patient-card-title">
                                    <v-icon icon="mdi-account" class="mr-2"></v-icon>
                                    Patient Information
                                </v-card-title>
                                <v-card-text class="mt-6">
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <div class="info-item">
                                                <div class="info-label">Name:</div>
                                                <div class="info-value">
                                                    {{
                                                        patientData.patientName
                                                    }}
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <div class="info-item">
                                                <div class="info-label">Email:</div>
                                                <div class="info-value">{{ patientData.email || 'Not provided' }}</div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="patientData.dob">
                                            <div class="info-item">
                                                <div class="info-label">Date of Birth:</div>
                                                <div class="info-value">{{ patientData.dob.toDate ?
                                                    formatDate(patientData.dob)
                                                    : 'Not provided' }}</div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <div class="info-item">
                                                <div class="info-label">Phone Number:</div>
                                                <div class="info-value">{{ patientData.phoneNumber || 'Not provided' }}
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <v-skeleton-loader v-else-if="fetchingPatient && patientId" type="card"
                            class="mb-4"></v-skeleton-loader>

                        <div class="tasks-container" v-if="selectedConversation?.requestTypeStatus">
                            <!-- Group tasks by request type -->
                            <div v-for="requestType in getAllRequestTypes()" :key="requestType"
                                class="task-section mb-5">
                                <div class="d-flex align-center mb-4 ">
                                    <h5 class="text-left font-weight-bold mb-0 flex-grow-1 ">
                                        {{ formatRequestType(requestType) }}
                                    </h5>
                                    <v-select density="compact" variant="outlined" hide-details
                                        class="filter-select  flex-shrink-0"
                                        :disabled="updatingRequestTypeStatus[requestType]"
                                        :model-value="selectedConversation.requestTypeStatus[requestType]"
                                        @update:model-value="updateRequestTypeStatus(requestType, $event)"
                                        :items="['COMPLETED', 'FAILED', 'IN_PROGRESS', 'PENDING']"
                                        :loading="updatingRequestTypeStatus[requestType]" style="max-width: 210px"
                                        prepend-inner-icon="mdi-checkbox-marked-circle-outline"></v-select>
                                </div>
                                <!-- Tasks for this request type -->
                                <div v-for="task in filterTasksByRequestType(requestType)" :key="task.id"
                                    @click="selectTask(task)" class="task-card mb-4">
                                    <div class="task-header">
                                        <div class="task-title text-left">
                                            <v-icon icon="mdi-checkbox-marked-circle"
                                                :color="getStatusColor(task.status)" class="mr-2" />
                                            {{ getTaskDisplayName(task.name) }}
                                        </div>
                                        <v-chip :color="getStatusColor(task.status)" variant="outlined" size="small">
                                            {{ task.status }}
                                        </v-chip>
                                    </div>
                                    <div class="task-description text-left">
                                        {{ task.description }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Original fallback display when no requestTypeStatus -->
                        <div class="tasks-container" v-else>
                            <h5 v-if="availableRequestTypes?.length > 0" class="mr-auto">
                                {{selectedConversation?.requestType?.map(type => formatRequestType(type)).join(',')}}
                            </h5>
                            <div v-for="task in displayTasks" :key="task.id" @click="selectTask(task)"
                                class="task-card">
                                <div class="task-header">
                                    <div class="task-title">
                                        <v-icon icon="mdi-checkbox-marked-circle" :color="getStatusColor(task.status)"
                                            class="mr-2" />
                                        {{ getTaskDisplayName(task.name) }}
                                    </div>
                                    <v-chip :color="getStatusColor(task.status)" variant="outlined" size="small">
                                        {{ task.status }}
                                    </v-chip>
                                </div>
                                <div class="task-description">
                                    {{ task.description }}
                                </div>
                            </div>
                        </div>

                        <div class="tasks-container" v-else>
                            <h5 v-if="availableRequestTypes?.length > 0" class="mr-auto">
                                {{selectedConversation?.requestType?.map(type =>
                                    formatRequestType(type)).join(',')}}
                            </h5>
                            <div v-for="task in displayTasks" :key="task.id" @click="selectTask(task)"
                                class="task-card">
                                <div class="task-header">
                                    <div class="task-title">
                                        <v-icon icon="mdi-checkbox-marked-circle" :color="getStatusColor(task.status)"
                                            class="mr-2" />
                                        {{ getTaskDisplayName(task.name) }}
                                    </div>
                                    <v-chip :color="getStatusColor(task.status)" variant="outlined" size="small">
                                        {{ task.status }}
                                    </v-chip>
                                </div>
                                <div class="task-description">
                                    {{ task.description }}
                                </div>
                            </div>
                        </div>
                    </v-window-item>
                </v-window>
            </v-card>

        </div>

        <v-navigation-drawer v-model="deleteDrawer" temporary location="right" :width="800">
            <div class="d-flex flex-column align-start pa-3" v-if="conversationToDelete">
                <h4 class="text-left">Are you sure you want to delete the conversation made on {{
                    formatDate(conversationToDelete?.startedAt || conversationToDelete?.createdAt) }}?</h4>
                <div class="mt-5">
                    <v-btn elevation="0" width="auto" class="mdhub-btn" @click="deleteDrawer = false">
                        Cancel</v-btn>
                    <v-btn elevation="0" width="auto" style="text-transform: none;" class="ml-2" :loading="deleting"
                        @click="deleteConversation">Delete
                        Conversation</v-btn>
                </div>
            </div>
        </v-navigation-drawer>

        <v-dialog v-model="taskDialog" width="800" @keydown.esc="taskDialog = false">
            <v-card class="task-dialog">
                <div class="dialog-header">
                    <h3>{{ getTaskDisplayName(selectedTask?.name) }}</h3>
                    <p class="description">{{ selectedTask?.description }}</p>
                </div>

                <div class="dialog-content">
                    <div v-if="selectedTask?.metadata && selectedTask.metadata.length > 0" class="metadata-fields">
                        <div v-for="(field, index) in selectedTask.metadata.slice(0)" :key="index"
                            class="metadata-field">
                            <template v-if="parseMetadataField(field)">
                                <div class="field-label">{{ parseMetadataField(field).label }}</div>
                                <div class="field-value-container">
                                    <v-text-field :model-value="parseMetadataField(field).value" readonly
                                        density="compact" variant="outlined"
                                        :placeholder="parseMetadataField(field).value ? '' : 'Not provided'"
                                        class="field-input"></v-text-field>
                                    <v-btn v-if="parseMetadataField(field).value" icon="mdi-content-copy" size="small"
                                        variant="text" @click="copyToClipboard(parseMetadataField(field).value)"
                                        class="copy-btn"></v-btn>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="status-section">
                        <h4 class="status-label">Update Status</h4>
                        <v-select :items="['COMPLETED', 'FAILED', 'PENDING', 'IN_PROGRESS']" v-model="updateStatus"
                            variant="outlined" density="compact"></v-select>

                        <!-- New appointment details section -->
                        <div v-if="selectedTask?.name === 'scheduleAppointment' && updateStatus === 'COMPLETED'"
                            class="appointment-details mt-4">
                            <h4 class="status-label">Appointment Details</h4>

                            <v-text-field v-model="phoneNumber" label="Phone Number" variant="outlined"
                                density="compact" :error-messages="!phoneNumber ? 'No phone number found' : ''"
                                class="mb-4">
                            </v-text-field>
                            <v-text-field v-model="appointmentDate" type="date" label="Appointment Date" required
                                class="mb-4" variant="outlined" density="compact">
                            </v-text-field>

                            <v-text-field v-model="appointmentTime" label="Time" type="time" variant="outlined"
                                density="compact" class="mb-4">
                            </v-text-field>

                            <v-select v-model="appointmentOption" :items="['Existing patient', 'New patient']"
                                label="Appointment Type" variant="outlined" density="compact">
                            </v-select>

                            <v-select v-model="visitType" :items="['In-person', 'Telehealth']" label="Visit Type"
                                variant="outlined" density="compact">
                            </v-select>

                            <v-select v-model="doctorId" :items="clinicDoctors" label="Doctors" variant="outlined"
                                density="compact" item-value="userId"
                                :item-title="item => `${item.name} | ${item.email}`"></v-select>
                            <v-autocomplete v-model="selectedAppointment" item-title="appointmentDescription"
                                :items="complexAppointmentTypes" return-object label="Appointment Type" required
                                class="mb-4" variant="outlined" density="comfortable" color="primary">
                            </v-autocomplete>
                        </div>

                        <div v-if="selectedTask?.name === 'addInsurance' && selectedConversation.insuranceVerification">
                            <h4 class="status-label font-weight-bold">Insurance verification</h4>
                            <h4 class="status-label">Status</h4>
                            <div class="field-value-container">
                                <v-text-field :model-value="selectedConversation.insuranceVerification.status" readonly
                                    density="compact" variant="outlined" class="field-input"></v-text-field>
                                <v-btn icon="mdi-content-copy" size="small" variant="text"
                                    @click="copyToClipboard(selectedConversation.insuranceVerification.status)"
                                    class="copy-btn"></v-btn>
                            </div>

                            <div v-for="(benefit, i) in selectedConversation.insuranceVerification?.benefits" :key="i">
                                <h4 class="status-label font-weight-bold">Benefit {{ i + 1 }}</h4>
                                <h4 class="status-label">Copay</h4>
                                <div class="field-value-container">
                                    <v-text-field :model-value="benefit?.copay" readonly density="compact"
                                        variant="outlined" class="field-input"></v-text-field>
                                    <v-btn icon="mdi-content-copy" size="small" variant="text"
                                        @click="copyToClipboard(benefit?.copay)" class="copy-btn"></v-btn>
                                </div>

                                <h4 class="status-label">Deductible</h4>
                                <h4 class="status-label">Remaining</h4>
                                <div class="field-value-container">
                                    <v-text-field :model-value="benefit?.deductible?.remaining" readonly
                                        density="compact" variant="outlined" class="field-input"></v-text-field>
                                    <v-btn icon="mdi-content-copy" size="small" variant="text"
                                        @click="copyToClipboard(benefit?.deductible?.remaining)"
                                        class="copy-btn"></v-btn>
                                </div>
                                <h4 class="status-label">Total</h4>
                                <div class="field-value-container">
                                    <v-text-field :model-value="benefit?.deductible?.total" readonly density="compact"
                                        variant="outlined" class="field-input"></v-text-field>
                                    <v-btn icon="mdi-content-copy" size="small" variant="text"
                                        @click="copyToClipboard(benefit?.deductible?.total)" class="copy-btn"></v-btn>
                                </div>

                                <hr>
                            </div>
                            <JsonViewer :value="selectedConversation?.insuranceVerification" copyable boxed sort
                                theme="jv-light" />
                        </div>
                    </div>
                </div>

                <v-divider></v-divider>

                <div class="dialog-actions">
                    <v-btn variant="text" @click="taskDialog = false">Cancel</v-btn>
                    <v-btn elevation="0" class="ml-3 mdhub-btn" style="width:auto" @click="updateTask()"
                        :loading="updatingTask">
                        Update
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import getCombinedConversations from '@/composables/getCombinedConversations';
import getInteractions from '@/composables/getInteractions';
import { getUserObject } from '@/composables/getUserObject';
import { regenerateConversationSummaryWhitelist } from '@/composables/isWhitelistedAccount';
import { projectFirestore } from '@/firebase/config';
import { Timestamp } from "@firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from 'moment-timezone';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { appointmentTypeValues, durationOfAppointment, typeOfVisit } from '../../views/ai-coordinator/constants';
import { roundMinutes } from '../../views/ai-coordinator/helpers';

const props = defineProps({
    selectedPatient: {
        type: Object,
        default: null
    }
});

const appointmentDate = ref(null)
const appointmentTime = ref(null)
const appointmentOption = ref(null)
const visitType = ref(null)
const doctorId = ref(null)

const requestTypeFilters = ref([])
const statusFilter = ref(null)
const statusTaskFilter = ref(null)
const patientIdFilter = ref(null); // New: Add patient ID filter
const updatingRequestTypeStatus = ref({})

const complexAppointmentTypes = ref([])
const selectedAppointment = ref(null)

const canRegenerate = ref(false)
canRegenerate.value = regenerateConversationSummaryWhitelist()

const stripTags = (text) => {
    return text ? text.replace(/<\/?[^>]+(>|$)/g, "") : '';
}

const formatDate = (date) => {
    return moment(new Timestamp(date.seconds, date.nanoseconds).toDate()).format('dddd, MMMM Do, YYYY HH:mm')
}

const tab = ref('conversation')
const generatingSummary = ref(false)
const functions = getFunctions();

const orders = [
    { val: 'asc', text: 'Oldest First' },
    { val: 'desc', text: 'Newest First' }
]
const order = ref('desc')

const regenerateSummary = async (id) => {
    if (generatingSummary.value) return;
    generatingSummary.value = true;
    const generateConversationSummary = httpsCallable(functions, 'generateConversationSummary');
    await generateConversationSummary({ id })
    generatingSummary.value = false;
}

const userObject = ref(null);
const conversations = ref([])
const paginationInfo = ref({
    isLoading: false,
    hasMore: true,
    hasActiveFilters: false,
    loadMore: () => { }
})
const pageSize = ref(10) // Default page size

const patientData = ref(null);
const fetchingPatient = ref(false);

const getConversations = () => {
    // Don't proceed if userObject isn't loaded yet
    if (!userObject.value?.clinicId) {
        console.log('Waiting for user object to load...');
        return;
    }

    // Reset pagination state when filters change
    conversations.value = [];

    // Set up filter conditions based on clinic ID
    const filterConditions = ['clinicId', '==', userObject.value.clinicId];

    // If patientId filter is active, apply it using a different approach
    const patientFilter = patientIdFilter.value;

    // Check if any filters are active
    const hasActiveFilters = requestTypeFilters.value.length > 0 ||
        statusFilter.value !== null ||
        statusTaskFilter.value !== null ||
        patientIdFilter.value !== null;

    // Set a large limit when filters are active, otherwise use the default pageSize
    const limit = hasActiveFilters ? 1000 : pageSize.value;

    const { error, loadMore, hasMore, isLoading } = getCombinedConversations(
        conversations,
        filterConditions,
        () => {
            // Custom callback that runs after conversations are loaded
            if (patientIdFilter.value && conversations.value.length > 0) {
                // If filtering by patient and we have results, select the first conversation
                selectConversation(conversations.value[0], true);
            } else {
                // Otherwise use the regular reselect logic
                reselect();
            }
        },
        order.value,
        { limit: limit, startAfter: null },
        patientFilter // Pass patientId as an additional parameter
    );

    paginationInfo.value.loadMore = loadMore;
    paginationInfo.value.hasMore = hasMore;
    paginationInfo.value.isLoading = isLoading;

    // Store the active filter state for the UI
    paginationInfo.value.hasActiveFilters = hasActiveFilters;
};


const reselect = async () => {
    if (!selectedConversation.value && conversations.value) {
        selectConversation(conversations.value[0], true)
        return
    }

    if (selectedConversation.value) {
        // Fetch the updated conversation directly from Firestore
        try {
            const collectionName = selectedConversation.value.type === 'conversation' ? 'conversations' : 'patient-questionnaires'
            const doc = await projectFirestore
                .collection(collectionName)
                .doc(selectedConversation.value.id)
                .get()

            if (doc.exists) {
                // Update the selected conversation with the latest data
                const updatedConversation = {
                    ...doc.data(),
                    id: doc.id,
                    type: selectedConversation.value.type,
                    requestType: doc.data().requestType || (collectionName === 'patient-questionnaires' ? ['schedule_appointment'] : ['general_inquiry'])
                }

                // Update in the local conversations array
                const index = conversations.value.findIndex(conv => conv.id === selectedConversation.value.id)
                if (index !== -1) {
                    conversations.value[index] = updatedConversation
                }

                // Update the selected conversation
                selectedConversation.value = updatedConversation

                // Refresh patient data if needed
                if (updatedConversation.patientId && (!patientData.value || patientData.value.id !== updatedConversation.patientId)) {
                    fetchPatientData(updatedConversation.patientId)
                }
            }
        } catch (error) {
            console.error('Error refetching conversation:', error)
            // Fallback to the old method if there's an error
            selectedConversation.value = conversations.value?.find(conversation => conversation.id === selectedConversation.value.id)
        }
    }
}

onMounted(async () => {
    userObject.value = await getUserObject();
    await getFilters()
    getConversations()
    complexAppointmentTypes.value = createComplexAppointmentTypes();
})

watch(() => props.selectedPatient, (newPatient) => {
    if (newPatient) {
        // Update the patientId filter when a patient is selected
        patientIdFilter.value = newPatient.id;
    } else {
        // Clear the filter when patient is deselected
        patientIdFilter.value = null;
    }
    // Refresh conversations with the new filter
    getConversations();
}, { immediate: true });

watch(order, () => {
    getConversations()
}, { deep: true })

const clearPatientFilter = () => {
    patientIdFilter.value = null;
    // Emit an event to notify parent component
    emit('patientFilterCleared');
    getConversations();
};

const loadMoreConversations = () => {
    if (paginationInfo.value.isLoading || !paginationInfo.value.hasMore) return

    // Now this function reference should work correctly
    paginationInfo.value.loadMore()
}

const setupScrollObserver = () => {
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting && paginationInfo.value.hasMore && !paginationInfo.value.hasActiveFilters) {
                loadMoreConversations()
            }
        })
    }, options)

    // Observe the load more element
    const target = document.querySelector('#load-more-trigger')
    if (target) observer.observe(target)

    return observer
}

let scrollObserver = null

onMounted(() => {
    // Wait for DOM to update before setting up the observer
    setTimeout(() => {
        scrollObserver = setupScrollObserver()
    }, 500)
})

onBeforeUnmount(() => {
    if (scrollObserver) {
        scrollObserver.disconnect()
    }
})

// Update watchers for filters to reset pagination
watch([requestTypeFilters, statusFilter, order, patientIdFilter, statusTaskFilter], () => {
    getConversations();
}, { deep: true });


const getTaskDisplayName = (taskName) => {
    const taskMapping = {
        createSummary: 'Create Summary',
        createPatient: 'Create Patient',
        scheduleAppointment: 'Schedule Appointment',
        addInsurance: 'Add Insurance',
        verifyInsurance: 'Verify Insurance',
        messageBillingTeam: 'Message Billing Team',
        confirmAppointment: 'Confirm Appointment',
        cancelAppointment: 'Cancel Appointment',
        scheduleNextAvailableAppointment: 'Schedule Next Available Appointment',
        medicationRefill: 'Medication Refill',
        medicationRefillExtra: 'Medication Refill Extra',
        takeMessage: 'Take Message',
    }

    return taskMapping[taskName] || taskName // Returns the mapped name or original if not found
}

/**
 * Converts snake_case strings to Title Case for display
 * For example: "medication_refill" becomes "Medication Refill"
 */
const formatRequestType = (requestType) => {
    if (!requestType) return '';
    // Split the snake_case string by underscores and capitalize each word
    return requestType
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

const parseMetadataField = (field) => {
    // Skip if no colon in string
    if (!field.includes(':')) return null

    const [label, ...valueParts] = field.split(':')
    let value = valueParts.join(':').trim() // Rejoin in case value contains colons

    return {
        label,
        value: value === 'undefined' ? '' : value
    }
}

const formatSummary = (html) => {
    if (!html) return 'No summary available';
    return html
        .replace(/\n/g, '<br>')
        .replace(/\**/g, '')
        .replace(/\s{2,}/g, ' ');
};


const getStatusColor = (status) => {
    switch (status) {
        case 'COMPLETED':
            return 'success'
        case 'PENDING':
            return 'bittersweet'
        case 'FAILED':
            return 'error'
        case 'IN_PROGRESS':
            return 'info'
        default:
            return 'grey'
    }
}

const displayTasks = computed(() => {
    if (!selectedConversation.value) return []

    const conversationTask = {
        id: 'interaction',
        name: 'Patient Interaction',
        description: 'Review the conversation between Sarah and the patient',
        status: selectedConversation.value.endedAt || selectedConversation.value.createdAt ? 'COMPLETED' : 'IN_PROGRESS'
    }

    return [conversationTask, ...selectedConversation.value.tasks || []]
})

const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text)
    } catch (err) {
        console.error('Failed to copy text: ', err)
    }
}


const selectedConversation = ref(null)
const interactions = ref(null)
const selectConversation = (conversation, isInitialSelection = false) => {
    if (conversation?.type === 'conversation') {
        const { error, documents } = getInteractions(conversation.id, interactions);
    } else {
        const tempInteractions = []

        for (let question of conversation?.schema) {
            if (question.type !== 'input_title') {
                tempInteractions.push({
                    speaker: 'ai-agent',
                    text: question.title
                })

                if (question.type === 'input_multiple') {
                    tempInteractions.push({
                        speaker: 'Patient',
                        text: question.response.join(', ')
                    })
                }
                else if (question.type === 'input_list') {
                    tempInteractions.push({
                        speaker: 'Patient',
                        text: question.response?.map(res => {
                            return res?.col + ': ' + res.responses.join(', ')
                        }).join(', ')
                    })
                }
                else if (question.type === 'input_likert') {
                    tempInteractions.push({
                        speaker: 'Patient',
                        text: question.response?.map(res => {
                            return res.question + ': ' + res.response
                        }).join(', ')
                    })
                }
                else {
                    tempInteractions.push({
                        speaker: 'Patient',
                        text: question.response
                    })
                }

            }
        }

        interactions.value = tempInteractions
    }
    selectedConversation.value = conversation

    // Fetch patient data if the conversation has a patientId
    if (conversation?.patientId) {
        fetchPatientData(conversation.patientId);
    } else {
        patientData.value = null;
    }

    if (isInitialSelection) {
        tab.value = 'tasks'
    }
}


const conversationToDelete = ref(null)
const deleteDrawer = ref(false)
const selectConversationToDelete = (conversation) => {
    conversationToDelete.value = conversation
    deleteDrawer.value = true
}
const deleting = ref(false)
const deleteConversation = async () => {
    deleting.value = true
    const deleteConversationFunction = httpsCallable(functions, 'deleteConversation');
    try {
        await deleteConversationFunction({ id: conversationToDelete.value.id, type: conversationToDelete.value.type })
    } catch (e) {
        console.error(e)
    }
    finally {
        deleting.value = false
        deleteDrawer.value = false
    }
}

const selectedTask = ref(null)
const taskDialog = ref(false)
const updateStatus = ref('')
const selectTask = (task) => {
    if (task.name === 'Patient Interaction') {
        tab.value = 'conversation'
        return
    }
    updateStatus.value = task.status
    selectedTask.value = task

    // Find and save phone number from createPatient task if it exists
    const createPatientTask = selectedConversation.value.tasks?.find(task => task.name === 'createPatient')
    const phoneMetadata = createPatientTask?.metadata?.find(field => field.startsWith('Phone Number:'))
    phoneNumber.value = phoneMetadata ? phoneMetadata.split(':')[1].trim() : ''

    taskDialog.value = true
}

const updatingTask = ref(false)
const phoneNumber = ref('')

// In the updateTask function, modify it to only refresh the selected conversation
// rather than reloading all conversations

const updateTask = async () => {
    const updateTaskFunction = httpsCallable(functions, 'updateTask');
    updatingTask.value = true

    try {
        const type = selectedConversation.value.type === 'conversation' ? 'conversations' : 'patient-questionnaires'

        let appointment = {}
        if (selectedTask.value.name === 'scheduleAppointment' &&
            updateStatus.value === 'COMPLETED' &&
            appointmentDate.value &&
            appointmentTime.value &&
            appointmentOption.value && phoneNumber.value &&
            visitType.value) {

            const [hours, minutes] = appointmentTime.value.split(':');
            const utcHours = parseInt(hours)
            const roundedMinutes = roundMinutes(minutes)
            const utcTime = `${utcHours}:${roundedMinutes}`;

            // new stuff
            const date = moment(appointmentDate.value).format('YYYY-MM-DD')
            const time = appointmentTime.value

            const mytimeozne = timezone.value

            const dateTimeString = `${date} ${time}`;

            const localMoment = moment.tz(dateTimeString, 'YYYY-MM-DD HH:mm', mytimeozne);
            const formattedDate = localMoment.utc().toISOString()

            // end of new stuff

            appointment = {
                formattedDate,
                date: moment(appointmentDate.value).format('YYYY-MM-DD'),
                time: utcTime,
                option: appointmentOption.value,
                phone: phoneNumber.value,
                visitType: visitType.value,
                doctorId: doctorId.value,
                appointmentDescription: selectedAppointment.value?.appointmentDescription
            }
        }

        await updateTaskFunction({
            entityId: selectedConversation.value.id,
            taskId: selectedTask.value.id,
            status: updateStatus.value,
            appointment,
            type,
        })

        // Close the task dialog
        taskDialog.value = false

        // CHANGED: Instead of refreshing all conversations, only refresh the selected one
        await refreshSelectedConversation()
    } catch (err) {
        console.error(err)
    } finally {
        updatingTask.value = false
    }
}

// Add this new function to refresh only the selected conversation
const refreshSelectedConversation = async () => {
    if (!selectedConversation.value) return

    try {
        const collectionName = selectedConversation.value.type === 'conversation'
            ? 'conversations'
            : 'patient-questionnaires'

        const doc = await projectFirestore
            .collection(collectionName)
            .doc(selectedConversation.value.id)
            .get()

        if (doc.exists) {
            // Update the selected conversation with the latest data
            const updatedConversation = {
                ...doc.data(),
                id: doc.id,
                type: selectedConversation.value.type,
                requestType: doc.data().requestType || (collectionName === 'patient-questionnaires' ? ['schedule_appointment'] : ['general_inquiry'])
            }

            // Update in the local conversations array
            const index = conversations.value.findIndex(conv => conv.id === selectedConversation.value.id)
            if (index !== -1) {
                conversations.value[index] = updatedConversation
            }

            // Update the selected conversation
            selectedConversation.value = updatedConversation

            // Refresh patient data if needed
            if (updatedConversation.patientId && (!patientData.value || patientData.value.id !== updatedConversation.patientId)) {
                fetchPatientData(updatedConversation.patientId)
            }
        }
    } catch (error) {
        console.error('Error refreshing selected conversation:', error)
    }
}

watch(taskDialog, (newValue) => {
    if (!newValue) {
        appointmentDate.value = null
        appointmentTime.value = null
        appointmentOption.value = null
        phoneNumber.value = ''
        visitType.value = null

        // If there's no selected conversation, also clear the patient data
        if (!selectedConversation.value) {
            patientData.value = null
        }
    }
})


const availableRequestTypes = ref([])
const clinicDoctors = ref([])
const timezone = ref(null)
const getFilters = async () => {
    const getCoordinatorFilters = httpsCallable(functions, 'getCoordinatorFilters');
    const { data } = await getCoordinatorFilters()
    availableRequestTypes.value = data.requestTypes
    clinicDoctors.value = data.doctors
    timezone.value = data.timezone
}


const filteredConversations = computed(() => {
    let convos = conversations.value;
    if (requestTypeFilters.value.length > 0) {
        convos = conversations.value.filter(conversation =>
            conversation.requestType.some(requestType => requestTypeFilters.value.includes(requestType))
        );
    }

    if (statusFilter.value) {
        if (statusFilter.value.length === 0) {
            statusFilter.value = null;
            return;
        }
        let allFilteredConversations = convos.filter(conversation => {
            if (!conversation.requestTypeStatus || Object.keys(conversation.requestTypeStatus).length === 0) {
                return false;
            }
            return Object.values(conversation.requestTypeStatus).find(status => statusFilter.value.includes(status));
        });

        convos = allFilteredConversations
        // convos = convos.filter(conversation => conversation.tasks && conversation.tasks?.filter(task => task.status === 'PENDING' || task.status === 'FAILED' || task.status === 'IN_PROGRESS').length > 0);
    } else if (statusTaskFilter.value) {

        if (statusTaskFilter.value === 'OPEN') {
            convos = convos.filter(conversation => conversation.tasks && conversation.tasks?.filter(task => task.status === 'PENDING' || task.status === 'FAILED' || task.status === 'IN_PROGRESS').length > 0);
        }
        if (statusTaskFilter.value === 'CLOSED') {
            convos = convos.filter(conversation => conversation.tasks && conversation.tasks?.filter(task => task.status === 'COMPLETED').length === conversation?.tasks.length);
        }
    }

    return convos;
});

const emit = defineEmits(['patientFilterCleared']);

const createComplexAppointmentTypes = () => {
    const appointments = clinicDoctors.value.map((clinician) => {

        const name = clinician.name;
        const clinicianId = clinician.userId;
        if (name) {
            return appointmentTypeValues.map((type) => {
                return typeOfVisit.map((visit) => {
                    return durationOfAppointment[visit].map((duration) => {
                        const appointmentDescription = `${type} | ${name} | ${visit} | ${duration} minutes`;
                        return { type, appointmentDescription, clinicianId, name };
                    });
                });
            });
        }
    });
    return appointments.flat(3).filter((appointment) => appointment?.appointmentDescription !== undefined);
};

watch(doctorId, (newVal) => {
    if (newVal) {
        selectedAppointment.value = null;
        complexAppointmentTypes.value = createComplexAppointmentTypes();
        complexAppointmentTypes.value = complexAppointmentTypes.value.filter((appointment) => appointment.clinicianId === doctorId.value);
    }
})

const resetFilters = () => {
    requestTypeFilters.value = [];
    statusFilter.value = null;
    statusTaskFilter.value = null
    order.value = 'desc';
    patientIdFilter.value = null; // Also reset the patient filter
    emit('patientFilterCleared'); // Notify parent
    getConversations();
};

const hasActiveFilters = computed(() => {
    return requestTypeFilters.value.length > 0 ||
        statusFilter.value !== null ||
        statusTaskFilter.value !== null ||
        order.value !== 'desc' ||
        patientIdFilter.value !== null;
});

// Function to fetch patient data by ID
const fetchPatientData = async (patientId) => {
    if (!patientId) {
        patientData.value = null;
        return;
    }

    fetchingPatient.value = true;
    try {
        const docRef = projectFirestore.collection('patients').doc(patientId);
        const doc = await docRef.get();

        if (doc.exists) {
            patientData.value = { id: doc.id, ...doc.data() };
        } else {
            patientData.value = null;
            console.log('No patient found with ID:', patientId);
        }
    } catch (error) {
        console.error('Error fetching patient data:', error);
        patientData.value = null;
    } finally {
        fetchingPatient.value = false;
    }
};

const getAllRequestTypes = () => {
    const taskRequestTypes = displayTasks.value
        .filter(task => task.requestType)
        .map(task => task.requestType);
    const conversationRequestTypes = selectedConversation.value?.requestType || [];
    return [...new Set([...conversationRequestTypes, ...taskRequestTypes])];
};

const filterTasksByRequestType = (requestType) => {
    return displayTasks.value.filter(task => task.requestType && task.requestType === requestType);
};

const updateRequestTypeStatus = async (requestType, status) => {
    try {
        updatingRequestTypeStatus.value[requestType] = true;
        if (!selectedConversation.value.requestTypeStatus) {
            selectedConversation.value.requestTypeStatus = {};
        }
        const type = selectedConversation.value.type === 'conversation' ? 'conversations' : 'patient-questionnaires'
        selectedConversation.value.requestTypeStatus[requestType] = status;
        const updateRequestTypeStatusFunction = httpsCallable(functions, 'updateRequestTypeStatus');
        await updateRequestTypeStatusFunction({
            entityId: selectedConversation.value.id,
            requestType: requestType,
            requestTypeStatus: status,
            type
        });
    } catch (error) {
        console.error('Error updating request type status:', error);
    } finally {
        await refreshSelectedConversation();
        updatingRequestTypeStatus.value[requestType] = false;
    }
};

const defineStatusIndication = (conversation) => {
    if (conversation.requestTypeStatus) {
        const isNotCompleted = Object.values(conversation.requestTypeStatus).some((status) => status !== 'COMPLETED')
        const hasInProgressRequestTypes = Object.values(conversation.requestTypeStatus).some((status) => status === 'IN_PROGRESS')
        const incompleteRequestTypes = Object.fromEntries(Object.entries(conversation.requestTypeStatus).filter(([_, value]) => value !== 'COMPLETED'));
        const joinedIncompletedRequestTypes = Object.keys(incompleteRequestTypes).join(',')
        return {
            color: isNotCompleted ? hasInProgressRequestTypes ? 'blue darken-1' : 'bittersweet' : 'green',
            text: hasInProgressRequestTypes ? 'Request is in progress' : isNotCompleted ? `Uncompleted requests: ${joinedIncompletedRequestTypes}.` : 'All request types are completed',
            icon: 'mdi-checkbox-marked-circle-outline'
        }
    } else {
        const isCompleted = conversation.tasks && conversation.tasks?.filter(task => task.status === 'COMPLETED').length === conversation?.tasks.length
        return {
            color: isCompleted ? 'green' : 'bittersweet',
            text: isCompleted ? 'All tasks have been completed' : 'Uncompleted tasks: ' + conversation.tasks?.filter(task => task.status === 'PENDING' || task.status === 'FAILED' || task.status === 'IN_PROGRESS')?.map(task => task.name).join(', '),
            icon: 'mdi-check-circle'
        }
    }

}
</script>

<style scoped>
.patient-filter-indicator {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
}

.chat-app-container {
    height: 100%;
    display: flex;
    width: 100%;
    padding-top: 14px;
    box-sizing: border-box;
    overflow: hidden;
}

/* Filter Styles */
.filter-header {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
    color: #555;
}

.filters-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin: 8px 0;
    padding: 12px;
    border-radius: 8px;
    background-color: #f8f9fa;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.filter-item {
    flex: 1;
    min-width: 180px;
}

.filter-select :deep(.v-field) {
    border-radius: 6px;
    background-color: white;
}

.filter-select :deep(.v-field__prepend-inner) {
    padding-right: 8px;
    opacity: 0.7;
}

.filter-select :deep(.v-field__input) {
    padding-top: 6px;
    min-height: 40px;
}

/* End Filter Styles */

.summary-text :deep(p) {
    margin-bottom: 1em;
}

.summary-text :deep(ul),
.summary-text :deep(ol) {
    margin-left: 1.5em;
    margin-bottom: 1em;
}

.summary-text {
    line-height: 1.5;
    overflow-y: auto;
    padding: 1rem;
    border-radius: 4px;
    max-height: 70vh;
}

.summary-text :deep(strong) {
    font-weight: 600;
}

.summary-text :deep(em) {
    font-style: italic;
}

.chat-list {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
}

.chat-interface {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-right: 4%;
}

.chat-interface .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;
}


.chat-list-items {
    flex: 1;
    overflow-y: auto;
    max-height: 70vh;
    position: relative;
}

.window {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}



.chat-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-y: scroll;
    max-height: 70vh;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    max-height: 70vh;
}

.message {
    display: flex;
    align-items: flex-start;
    max-width: 80%;
}

.bot-message {
    align-self: flex-start;
}

.user-message {
    align-self: flex-end;
    flex-direction: row-reverse;
}

.message-content {
    background-color: #e8edf2;
    text-align: left;
    border-radius: 14px;
    padding: 8px 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.bordered-avatar {
    border: 1px solid;
    border-color: #B0B0B0;
}

.bot-message .bordered-avatar {
    border-color: #B0B0B0;
}

.user-message .message-content {
    background-color: white;
    border: 0.5px solid #B0B0B0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.message-sender {
    font-weight: bold;
    margin-bottom: 4px;
}

.message-text {
    margin: 0;
}

.selected-chat {
    background-color: #e8edf2;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.75;
    }

    50% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0.75;
    }
}

.pulsing-icon {
    animation: pulse 1.5s infinite;
}

.patient-chart-options ::v-deep .v-tab--selected span {
    color: var(--bittersweet);
    font-size: medium;
}

.tasks-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    overflow-y: auto;
    max-height: none;
    flex: 1;
}

.task-card {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.task-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.task-title {
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    color: #2c3e50;
}

.task-description {
    color: #666;
    font-size: 0.95em;
    line-height: 1.5;
    display: flex;
}

.report-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.generate-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
}

.generate-btn {
    font-weight: 500;
    text-transform: none;
    min-width: 180px;
}

.task-dialog {
    padding: 0;
}

.dialog-header {
    padding: 20px 24px;
}

.dialog-header h3 {
    font-size: 1.5rem;
}

.description {
    color: #666;
    font-size: 0.95rem;
    margin: 0;
}

.dialog-content {
    padding: 20px 24px;
}

.metadata-fields {
    display: grid;
}

.metadata-field {
    display: flex;
    flex-direction: column;
}

.field-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #666;
}

.field-value-container {
    display: flex;
    align-items: center;
}

.field-input {
    flex: 1;
}

.status-section {
    margin-top: 16px;
}

.status-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #666;
    margin-bottom: 8px;
}

.dialog-actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
}

.reset-btn {
    margin-left: 8px;
}

.load-more-container {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    width: 100%;
}

/* Update your existing chat-list-items style to prevent any height issues */
.chat-list-items {
    flex: 1;
    overflow-y: auto;
    max-height: 70vh;
    position: relative;
}

.id-container {
    display: flex;
    align-items: center;
}

.copy-id-btn {
    opacity: 0.6;
    margin-top: -2px;
    transition: opacity 0.2s;
}

.copy-id-btn:hover {
    opacity: 1;
}

.patient-info-card {
    background-color: #f8fbff;
    border: 1px solid #e0e7f1;
    border-radius: 12px;
    transition: all 0.2s ease;
    width: 100%;
}

.patient-card-title {
    font-size: 1.1rem;
    color: #2c3e50;
    border-bottom: 1px solid #e0e7f1;
    padding-bottom: 12px;
}

.info-item {
    display: flex;
    margin-bottom: 12px;
    flex-wrap: wrap;
    width: 100%;
}

.info-label {
    font-weight: 600;
    min-width: 120px;
    color: #566273;
    flex: 0 0 120px;
}

.info-value {
    flex: 1;
    word-break: break-word;
    min-width: 0;
    max-width: 100%;
}

/* Media query for small screens */
@media (max-width: 768px) {
    .info-item {
        flex-direction: column;
    }

    .info-label {
        flex: 0 0 auto;
        margin-bottom: 4px;
    }

    .info-value {
        width: 100%;
    }

    .patient-info-card :deep(.v-card-text) {
        padding: 8px;
    }
}
</style>