<template>
    <v-container fluid style="height: 100vh; max-width: 1300px; padding-top: 60px;">
        <v-row v-if="!isLoading" class="fill-height" align="center" justify="center">
            <v-col cols="12" md="10">
                <v-row>
                    <v-col cols="12" md="6" class="pa-0 hidden-sm-and-down d-flex flex-column">
                        <!-- Títle and Subtitle-if Team Plan- -->
                        <div v-if="isTeamPlan" class="text-left mb-4">
                            <h1 class="text-h4 font-weight-bold mb-2">Thank You!</h1>
                            <p class="subtitle-1 m-0">
                            Your subscription has been successfully created. 
                            Please complete your clinic setup.
                            </p>
                        </div>
                        <!-- Image -->
                        <v-img :src="require('@/assets/mdhub-therapist.jpg')" cover class="rounded-xl elevation-1"
                            style="border-radius: 16px; max-width: 100%; max-height: 450px;"></v-img>
                    </v-col>
                    <!-- Content Column -->
                    <v-col cols="12" md="6" class="d-flex flex-column justify-center">
                        <!-- Team Plan Clinic Creation Form -->
                        <template v-if="isTeamPlan"> <!-- && !userObject.value.clinicId -->
                        <v-form ref="clinicForm" v-model="isFormValid" @submit.prevent="createClinic" class="px-4">
                            <v-text-field v-model="clinicName" :rules="[v => !!v || 'Clinic name is required']"
                            label="Clinic Name" required variant="outlined" class="mb-3"></v-text-field>

                            <v-text-field v-model="clinicAddress" :rules="[v => !!v || 'Clinic address is required']"
                            label="Clinic Address" required variant="outlined" class="mb-3"></v-text-field>

                            <v-text-field v-model="domains" :rules="[v => !!v || 'At least one domain is required']"
                            label="Email Domains (separated by commas)"
                            :hint="`Example: ${userDomain}, gmail.com`"
                            persistent-hint required variant="outlined" class="mb-3"></v-text-field>

                            <v-text-field v-model="phoneNumbers" :rules="[v => !!v || 'At least one phone number is required',
                                v => /\+[\d\s]+(,\s*\+[\d\s]+)*/.test(v) || 'Invalid phone numbers format']"
                            label="Phone Numbers (with country code, separated by commas)"
                            hint="Example: +1234567890, +1934967490" persistent-hint
                            required variant="outlined" class="mb-3"></v-text-field>

                            <v-text-field v-model.number="clinicianCount" label="Number of Seats" type="number"
                                hint="Total available seats for your clinic" persistent-hint
                                required variant="outlined" class="mb-3" readonly></v-text-field>

                            <v-text-field v-model="clinicianEmails" 
                                :rules="[
                                    v => validateClinicianEmails(v),
                                    v => validateClinicianCount(v) || `Cannot exceed ${clinicianCount.value} seats (including you)`
                                ]"
                                label="Clinician Emails (separated by commas)"
                                hint="Add emails of clinicians you want to invite"
                                persistent-hint variant="outlined" class="mb-3"></v-text-field>
                            
                            <div v-if="cliniciansInfo.total > clinicianCount" class="text-caption mb-2">
                                <span :class="cliniciansInfo.valid ? '' : 'text-error'">
                                    Using {{cliniciansInfo.total}} of {{clinicianCount}} seats 
                                    ({{cliniciansInfo.valid ? 'valid' : 'exceeds limit'}})
                                </span>
                            </div>

                            <v-tooltip location="right" max-width="300">
                            <template #activator="{ props }">
                                <div v-bind="props">
                                <v-checkbox v-model="sharePatients" label="Share patients between clinicians"
                                    color="primary" hide-details class="mb-3"></v-checkbox>
                                </div>
                            </template>
                            <span style="color:white">
                                This setting allows all clinicians belonging to the clinic to access each other's patients. If unchecked, users can only view patients they have created.
                            </span>
                            </v-tooltip>

                            <v-btn type="submit" color="bittersweet" block :disabled="!isFormValid" :loading="clinicCreationPending">
                                <span style="color:white">Create Clinic</span>
                            </v-btn>
                        </v-form>
                        </template>
                        <!-- Default Subscription Confirmation -->
                        <template v-else>
                        <div class="px-4">
                            <h1 class="text-h4 font-weight-bold mb-2">Thank You!</h1>
                            <p class="subtitle-1">
                            Your subscription has been successfully created.
                            You are being redirected to your patients' list.
                            </p>
                            <v-card-actions class="justify-center pa-6">
                                <div class="d-flex justify-center">
                                    <SavingSpinner label="" style="position: relative;" />
                                </div>
                            </v-card-actions>
                        </div>
                        </template>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- Loading View -->
        <v-row v-else justify="center" align="center" style="height: 100vh;">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import getUser from '@/composables/getUser'
import { getUserCollection } from '@/composables/userService'
import SavingSpinner from '@/components/SavingSpinner.vue'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { trackEvent, CLINIC_CREATED, CLINIC_CREATED_ERROR } from '@/utilities/analyticsService';

const { user } = getUser()
const userObject = ref({})
// new clinic plan variables
const isTeamPlan = ref(false)
const clinicianCount = ref(null)
const clinicName = ref('')
const clinicAddress = ref('')
const isFormValid = ref(false)
const availableProducts = ref(null)
const isLoading = ref(true)
const domains = ref('')
const phoneNumbers = ref('')
const sharePatients = ref(false)
const userDomain = ref('')
const clinicCreationPending = ref(false)
const clinicianEmails = ref('')

const cliniciansInfo = computed(() => {
    if (!clinicianEmails.value) return { total: 1, valid: true };
    
    const emails = clinicianEmails.value
        .split(',')
        .map(email => email.trim())
        .filter(email => email.length > 0 && email !== userObject.value.email);
    
    // Add 1 for the user's email
    const total = emails.length + 1;
    const valid = total <= clinicianCount.value;
    
    return { total, valid, emails };
});

// Validate clinician emails
const validateClinicianEmails = (value) => {
    if (!value) return true;
    
    const emails = value.split(',').map(email => email.trim()).filter(Boolean);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    for (const email of emails) {
        if (!emailRegex.test(email)) {
            return `"${email}" is not a valid email address`;
        }
    }
    
    return true;
};

// Validate clinician count
const validateClinicianCount = (value) => {
    return cliniciansInfo.value.valid;
};

onMounted(async () => {
    if (user.value) {
        userObject.value = await getUserCollection(user.value.uid)

        // Set user domain
        const emailParts = userObject.value.email.split('@')
        userDomain.value = emailParts.length > 1 ? emailParts[1] : ''
        domains.value = userDomain.value
        
        // Fetch and check available products
        await fetchAvailableProducts()

        // If not a team plan, redirect to patients list
        if (!isTeamPlan.value || userObject.value.clinicId) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                'event': 'subscription_completed',
                'user_id': user.value.uid,
                'user_email': userObject.value.email
            })
            
            setTimeout(redirectToPatientsList, 2000)
        }
    }
})

const redirectToPatientsList = () => {
    window.location.href = '/patients/user'
}

const fetchAvailableProducts = async () => {
    isLoading.value = true
    try {
        const functions = getFunctions()
        const getProducts = httpsCallable(functions, 'getAvailableProducts')
        const result = await getProducts({ userId: user.value.uid })
        availableProducts.value = result.data
        
        // Determine plan type
        const currentPlan = determineCurrentPlan(result.data)
        isTeamPlan.value = currentPlan === 'team'
        
        // Set clinician count for team plan
        if (isTeamPlan.value) {
            const storedClinicianCount = localStorage.getItem('clinicianCount')
            clinicianCount.value = storedClinicianCount ? parseInt(storedClinicianCount) : 0
        }
    } catch (error) {
        console.error('Error checking subscription plan:', error)
        trackEvent( CLINIC_CREATED_ERROR, {
            userId: user.value.uid,
            userEmail: userObject.value.email,
            error: error.message
        })
        setTimeout(redirectToPatientsList, 2000)
    } finally {
        isLoading.value = false
    }
}

const determineCurrentPlan = (productsData) => {
    if (!productsData?.userProducts?.length) {
        console.log('No subscriptions found, returning free')
        return 'free'
    }

    // Check all user products for their plans
    const userPlans = productsData.userProducts
        .map(product => product.metadata?.plan)
        .filter(Boolean)

    console.log('User plans:', userPlans)

    // Return the highest tier plan if user has multiple subscriptions
    if (userPlans.includes('team')) return 'team'
    if (userPlans.includes('pro')) return 'pro'
    if (userPlans.includes('lite')) return 'lite'

    return 'free'
}

const createClinic = async () => {
    if (!isFormValid.value) return

    try {
        clinicCreationPending.value = true
        const functions = getFunctions();
        const createClinicFunction = httpsCallable(functions, 'createClinic');
        // Process form data
        const processedDomains = domains.value.split(',')
            .map(d => d.trim())
            .filter(d => d.length > 0)
        
        const processedPhones = phoneNumbers.value.split(',')
            .map(p => p.trim().replace(/\s+/g, '')) // Remove spaces
            .filter(p => p.length > 0)

        let clinicians = [userObject.value.email];
        if (clinicianEmails.value) {
            const additionalClinicians = clinicianEmails.value
                .split(',')
                .map(email => email.trim())
                .filter(email => email.length > 0 && email !== userObject.value.email);
            
            clinicians = [...clinicians, ...additionalClinicians];
        }
        
        // Prepare clinic document
        const clinicData = {
            name: clinicName.value,
            address: clinicAddress.value,
            domains: processedDomains,
            phoneNumbers: processedPhones,
            seats: clinicianCount.value.toString(),
            sharePatients: sharePatients.value,
            admins: [userObject.value.email],
            clinicians: clinicians,
        }

        const result = await createClinicFunction(clinicData);
        console.log('Clínica creada con ID:', result.data.id);
        
        // Track success event with clinic ID
        window.dataLayer = window.dataLayer || []
        window.dataLayer?.push({
            'event': 'clinic_created',
            'user_id': user.value.uid,
            'user_email': userObject.value.email,
            'clinic_id': result.data.id
        })

        trackEvent(CLINIC_CREATED, {
            userId: user.value.uid,
            userEmail: userObject.value.email,
            clinicId: result.data.id
        })

        // Redirect to patients list
        redirectToPatientsList()

    } catch (error) {
        console.error('Error creating clinic:', error)
        trackEvent(CLINIC_CREATED_ERROR, {
            userId: user.value.uid,
            userEmail: userObject.value.email,
            error: error.message
        })
    } finally {
        clinicCreationPending.value = false
    }
}

</script>

<style scoped>
.v-card-subtitle {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: 4.5em;
}

.v-card-title {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: 4.5em;
}

@media (max-width: 600px) {
    .v-card-title {
        font-size: 18px;
    }
}

.d-flex.flex-row {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
}

.pa-0.hidden-sm-and-down {
    display: flex;
    flex-direction: column;
}

.v-img {
    flex-grow: 1;
}

.text-error {
    color: #f44336;
}
</style>