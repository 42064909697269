<template>
    <div class="d-flex flex-column">
        <v-text-field label="Title" v-model="item.title" :disabled="!!item.cannotEdit"></v-text-field>
        <v-text-field label="Description" v-model="item.description" :disabled="!!item.cannotEdit"></v-text-field>
        <v-checkbox :disabled="!!item.cannotEdit" class="mt-2" v-model="item.required" label="Required"></v-checkbox>

        <CardActions :item="item" @save="save" @remove="remove" @moveUp="moveUp" @moveDown="moveDown"
            @duplicate="duplicate" />

    </div>
</template>


<script setup>
import { defineProps, defineEmits } from 'vue';
import CardActions from './CardActions.vue';

const props = defineProps({
    item: Object,
})

const emit = defineEmits(['save', 'remove', 'moveUp', 'moveDown', 'duplicate']);

const save = () => {
    emit('save')
}

const remove = () => {
    emit('remove', {
        id: props.item.id
    })
}

const moveUp = () => {
    emit('moveUp')
}

const moveDown = () => {
    emit('moveDown')
}

const duplicate = () => {
    emit('duplicate')
}
</script>